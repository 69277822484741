import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { IDatePickerConfig } from "ng2-date-picker";
import { DatePipe, isPlatformBrowser } from "@angular/common";
import { Meta, Title } from "@angular/platform-browser";
import * as dayjs from "dayjs";
import { Router } from '@angular/router';

@Component({
  selector: 'app-offer-produce',
  templateUrl: './offer-pro.component.html',
  styleUrls: ['./offer-pro.component.scss'],
  providers: [DatePipe],
})
export class OfferProComponent implements OnInit {

  // The postal code not accepted by Monabee
  private cpNoAvailable: string[] = ['75116', '14400'];

  /* HOURS SELECTION */
  public availableTimes: string[] = ['09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00'];
  public timesIndex: number = 0;

  /* CALENDAR CONFIGURATION */
  public selectedDate!: Date;

  /* Width of the window user */
  public currentWidth = 2000;

  
  /** List of reviews **/
  public reviews: Array<any> = [];



  public datePickerConfig: IDatePickerConfig = {
    firstDayOfWeek: 'su',
    disableKeypress: false,
    allowMultiSelect: false,
    closeOnSelect: false,
    closeOnSelectDelay: 100,
    onOpenDelay: 0,
    weekDayFormat: 'ddd',
    showNearMonthDays: false,
    enableMonthSelector: true,
    yearFormat: 'YYYY',
    format: 'DD-MM-YYYY',
    min: dayjs(),
    isDayDisabledCallback: (date: any) => {
      // Disabled week-ends selection (samedi et dimanche)
      const isWeekend = date.day() === 0 || date.day() === 6;

      // Disabled dates before the date of the current day
      const currentDate = dayjs();
      const isBeforeToday = date.isBefore(currentDate, 'day');

      return isWeekend || isBeforeToday;
    },
  };

  /* User datas */
  public formDatas = {
    acceptRecall: false,
    phoneNumber: "",
    name: '',
    email: '',
    cp: '',
    userType1: 'pro',
    userType2: 'pro',
    userType3: 'pro',
    company: '',
    message: '',
    meetingDate: Date,
    meetingHour: '',
  };


  /* MODALS */
  public formIsValidated: boolean = false;
  public getProForm: boolean = false;
  public getContactModal: boolean = false;
  public getCall: boolean = false;
  public openMessage: boolean= false;
  public getMeeting: boolean = false;

  // Step validate for modal
  public callIsValidated: boolean = false;
  public messageIsValidated: boolean = false;
  public meetingDateIsValidated: boolean = false;
  public meetingFormIsValidated: boolean = false;
  public meetingIsValidated: boolean = false;

  // Step count for slider
  public stepCount: number = 1;

  public isMobile = false;


  constructor(
    private datePipe: DatePipe,
    private titleService: Title,
    private metaService: Meta,
    private ngZone: NgZone,
    private router:Router,
    
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 

    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1000;
      this.currentWidth = window.innerWidth;
    }


  }

  ngOnInit(): void {
    // Set title and meta description
    this.titleService.setTitle('Installation de Panneaux solaires en entreprise - Monabee');
    this.metaService.updateTag({ name: 'description', content: 'Réduisez la facture d\'électricité  de votre entreprise. Monabee équipe et pose vos panneaux  solaires.  Devis gratuit, plus de 6 000 clients en France. 97% de satisfaction.' });

    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.currentWidth = window.innerWidth;
    }

    this.getGoogleReviews();
  }

  /* GLOBAL MODALS */

  public getValidateForm(): void {
    this.formIsValidated = true;
  }

  public closeValidateForm(): void {
    this.formIsValidated = false;
  }

  public setContactModal(): void {
    this.getContactModal = true;
  }

  public closeContactModal(): void {
    this.getContactModal = false;
  }

  /* CONTACT MODALS */

  /**
   * Function to get the time available
   */
  public getDisplayedTimes(): string[] {
    return this.availableTimes.slice(this.timesIndex * 4, (this.timesIndex + 1) * 4);
  }

  /**
   * Function to show more available time
   */
  public showMoreTimes(): void {
    this.timesIndex++;
    if (this.timesIndex * 5 >= this.availableTimes.length) {
    }
  }

  /**
   * To show top time selection
   */
  public showLessTimes(): void {
    this.timesIndex--;
  }

  /**
   * To display the date in correct string format
   * @param date
   */
  public getFormattedDate(date: Date): string {
    const formattedDate = this.datePipe.transform(date, 'dd MMMM yyyy', undefined, 'fr-FR');
    return formattedDate ? formattedDate : '';
  }

  /**
   * Check if user want a meeting
   */
  public userGetMeeting(): void {
    this.getMeeting = !this.getMeeting;
    this.meetingDateIsValidated = false;
    this.meetingFormIsValidated = false;
    this.meetingIsValidated = false;
    this.getContactModal = false;
  }

  /**
   * Check if user want a call
   */
  public userGetCall(): void {
    this.getCall = !this.getCall;
    this.callIsValidated = false;
    this.getContactModal = false;
  }

  /**
   * Check if user want to send message
   */
  public userGetMessage(): void {
    this.openMessage = !this.openMessage;
    this.messageIsValidated = false;
    this.formDatas.userType3 = 'pro';
    this.getContactModal = false;
  }

  /**
   * To display validate meeting step
   */
  public validateMeeting(step: number): void {
    if (step === 1) {
      this.getMeeting = false;
      this.meetingDateIsValidated = true;
      this.meetingFormIsValidated = false;
      this.meetingIsValidated = false;
    }
    else if (step === 2) {
      this.getMeeting = false;
      this.meetingDateIsValidated = false;
      this.meetingFormIsValidated = true;
    }
    else if (step === 3) {
      this.getMeeting = false;
      this.meetingDateIsValidated = false;
      this.meetingFormIsValidated = false;
      this.meetingIsValidated = true;
    }
  }

  /**
   * To display validate call step
   */
  public validateCall(): void {
    this.getCall = false;
    this.callIsValidated = true;
  }

  /**
   * To display validate message step
   */
  public validateMessage(): void {
    this.openMessage = false;
    this.messageIsValidated = true;
  }

  /**
   * Close meeting modal
   */
  public closeMeetingModal(): void {
    this.getMeeting = false;
    this.meetingDateIsValidated = false;
    this.meetingFormIsValidated = false;
    this.meetingIsValidated = false;
  }

  /**
   * Close call modal
   */
  public closeCallModal(): void {
    this.getCall = false;
    this.callIsValidated = false;
  }

  /**
   * Close message modal
   */
  public closeMessageModal(): void {
    this.openMessage = false;
    this.messageIsValidated = false;
  }

  /* NAVIGATION SLIDER */

  /**
   * Function to up the step in slider
   */
  public nextStep(): void {
    if (this.stepCount < 2) {
      this.stepCount++;
    } else {
      this.stepCount = 1;
    }
  }

  /**
   * Function to return to the previous step in slider
   */
  public previousStep(): void {
    if (this.stepCount > 1) {
      this.stepCount--;
    } else {
      this.stepCount = 2;
    }
  }

  /**
   * Function to swipe left on mobile
   */

  onSwipeLeft(): void {
    if (this.isMobile && this.stepCount == 1) {
      this.nextStep();
    }
  }

  /**
   * Function to swipe right on mobile
   */

  onSwipeRight(): void {
    if (this.isMobile && this.stepCount == 2) {
      this.previousStep();
    }
  }


    /**
   * 
   * @param stepNumber 
   * Function to go to a specific step in the slider
   */

    goToStep(stepNumber: number) {
      this.stepCount = stepNumber;
   
    }

  /* USER SELECTION */
  /**
   * Function to change value on select change
   * @param number id of the selector
   * @param value
   */
  public onSelectChange(number: number, value: any): void {
    if (number === 1) {
      this.formDatas.userType1 = value;
    }
    else if (number === 2) {
      this.formDatas.cp = value;
    }
    else if (number === 3) {
      this.formDatas.userType2 = value;
    }
    else if (number === 4) {
      this.formDatas.meetingHour = value;
    }
    else if (number === 5) {
      this.formDatas.userType3 = value;
    }
  }

  /**
   * Function to get postal code selected in google recommendation
   * @param string address selected
   */
  public onPlaceSelected(postal_code: string): void {
    this.formDatas.cp = postal_code || '';
  }

  /* VALIDATIONS */

  /**
   * Function to know if user cp correspond to the Monabee intervention
   */
  public isPostalCodeNotAvailable(): boolean {
    return this.cpNoAvailable.includes(this.formDatas.cp);
  }

  /**
   * Function to know if the postal code is valid
   */
  public isPostalCodeValid(): boolean {
    if (this.formDatas.cp === '' || this.formDatas.cp === null) {
      return false;
    }
    const regex = /^\d{2}[ ]?\d{3}$/;
    return regex.test(this.formDatas.cp);
  }

  /**
   * Function to scroll top
   */
  public scrollTop(): void {
    window.scrollTo(0, 0);
  }


   /**
   * Get the google reviews
   */
   public getGoogleReviews(): void {
    if (isPlatformBrowser(this.platformId)) {
      const placeId = 'ChIJYTlcBKzs9EcRkK7WiWxzT7w';
      const placesService = new google.maps.places.PlacesService(document.createElement('div'));

      // Request place details including reviews
      placesService.getDetails({ placeId: placeId, fields: ['reviews'], language: 'fr-fr' }, (place: any, status: any) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.ngZone.run(() => {
            this.reviews = place.reviews;
          });
        }
      });
    }
  }


  /**
   * Scroll to inquiry section
   * @returns void
   * */

  public scrollToInquiry() {
      if (isPlatformBrowser(this.platformId)) {
          document.getElementById('projectInquiry')?.scrollIntoView({behavior: 'smooth'});
      }
  }
  

  
  
  /** Function to go to a specific link */

  goToLink(url: string){
    this.router.navigateByUrl(url);
  }
  
}
