import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { isPlatformBrowser } from "@angular/common";
import { StorageService } from "./services/storage.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private route: ActivatedRoute,
        private storage: StorageService
    ) {
    }

    ngOnInit() {

        // Execute code only on browser
        if (isPlatformBrowser(this.platformId)) {

            // Delay to be sure to get the query params
            setTimeout(() => {
                this.storage.setValue('gclid', this.route.snapshot.queryParamMap.get('gclid') || '');
                this.storage.setValue('utm_source', this.route.snapshot.queryParamMap.get('utm_source') || '');
                this.storage.setValue('utm_campaign', this.route.snapshot.queryParamMap.get('utm_campaign') || '');
                this.storage.setValue('utm_medium', this.route.snapshot.queryParamMap.get('utm_medium') || '');
                this.storage.setValue('gad_source', this.route.snapshot.queryParamMap.get('gad_source') || '');
            }, 100);
        }
    }
}
