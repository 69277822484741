import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
  ) {
  }

  /**
   * Set a new key into the local storage
   * @param key The key
   * @param value The key
   */
  public setValue(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  /**
   * Get the value from local storage
   */
  public getValue(key: string): string {
    return localStorage.getItem(key) || '';
  }
}
