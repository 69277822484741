<div class="guide-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>
  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb [links]="[{title: 'Nos guides', link: '/guides'}, {title: guide?.data?.title}]"></Breadcrumb>
    </div>
  </div>

  <!-- CONTENT -->
  <div class="guide-content">
    <ng-container *ngIf="guide !== null">
      <TitleAtom type="h1" text="{{ guide.data.title }}" align="center" color="dark" size="x-large" weight="semi-bold"></TitleAtom>

      <div>
        <div class="section-left">
          <Image imageExternal="{{ guide.data.image.second.url }}" alt="{{ guide.data.image.alt }}" width="600px" mobileWidth="100%" borderRadius="radius"></Image>
        </div>
        <div class="section-right">
          <CmsHtmlRendererAtom [data]="guide.data.body"></CmsHtmlRendererAtom>

          <ul class="advantages">
            <li *ngFor="let advantage of guide.data.advantages">
              <Image type="icon" image="check-circle-blue" width="32px" mobileWidth="28px"></Image>
              {{ advantage.advantage_text }}
            </li>
          </ul>

          <div class="download">
            <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre email*"></ParagraphAtom>
            <InputAtom (input)="onInputChange(1)" name="email" type="email" [(value)]="email" [style]="'basic'" color="{{(email === '' || checkEmail()) ? 'light' : 'red'}}"></InputAtom>
            <ParagraphAtom *ngIf="!checkEmail() && isEmailInputDirty && email !== ''" color="red" size="small" weight="medium" text="Email invalide."></ParagraphAtom>
            <CheckboxAtom class="guide-checkbox" name="consent" label="J’autorise Monabee à m’envoyer par e-mail le guide et à m’envoyer des informations utiles sur les panneaux solaires" [(value)]="consent"></CheckboxAtom>

            <div>
              <ButtonAtom (click)="downloadFile()" size="default" color="yellow" [style]="'basic'" text="Télécharger gratuitement" [isDisabled]="!canDownload()"></ButtonAtom>
            </div>
            <div class="notification">
              <Notification [isActive]="isDownload" text="Votre demande a bien été prise en compte" type="success"></Notification>
            </div>
          </div>


    

        </div>
      </div>
      <div class="seo-section">
        <TitleAtom type="h2" text="Découvrez Nos Livres Blancs Photovoltaïques Gratuits" align="left" color="dark" size="large" weight="semi-bold"></TitleAtom>

        <div class="seo-paragraph">
          <ParagraphAtom color="dark" size="small" weight="medium" text="Monabee vous propose une série de livres blancs gratuits dédiés à l'énergie solaire et aux solutions photovoltaïques. Ces guides complets offrent des informations détaillées et des conseils pratiques pour maximiser votre investissement dans l'énergie solaire. Vous y trouverez des analyses de rentabilité, des solutions de recharge solaire pour véhicules électriques, ou encore l’intérêt du photovoltaïque pour les bâtiments tertiaires. Chaque livre blanc inclut des études de cas, des comparaisons de solutions et des informations sur les incitations fiscales et aides financières. Téléchargez dès maintenant nos livres blancs gratuits pour réussir votre transition énergétique."></ParagraphAtom>
 
        </div>
      </div>
    </ng-container>
  </div>
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>
