<div class="offer-produce-container">
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>

  <!-- HEROBANNER -->
  <div class="offer-herobanner">

    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb color="light" [links]="[{title: 'Produire mon électricité'}]"></Breadcrumb>
    </div>

    <div id="offer-produce-form" class="offer-herobanner-content">
      <div id="produceOffer" class="content">
        <TitleAtom [mobileSize]="18" color="yellow" weight="semi-bold" size="small" text="Produisez votre électricité verte "></TitleAtom>
<!--        <TitleAtom [mobileSize]="18" color="yellow" weight="regular" size="nano" text="option Pack Securee’z – Maintenance et entretien de vos panneaux solaires"></TitleAtom>-->
        <TitleAtom type="h1" color="light" weight="semi-bold" size="x-large" text="Devenez le plus autonome en énergie grâce à l’installation de panneaux solaires"></TitleAtom>
      
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="Depuis plus de 12 ans, Monabee accompagne les particuliers à produire et consommer leur propre électricité via l’installation de panneaux solaires en autoconsommation."></ParagraphAtom>
       
        <TitleAtom type="p" color="light" weight="medium-weight" size="small" text="Une installation de panneaux solaires en toute sécurité"></TitleAtom>
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="Inclus et unique sur le marché, notre service premium <a class='white-link' href='domotique-solaire-monitoring-photovoltaique/'><strong><u>Secureez</strong></u></a> ! Maintenance, garanties pièces, main d‘œuvre et déplacement jusqu’à 30 ans."></ParagraphAtom>
       
        <TitleAtom type="p" color="light" weight="medium-weight" size="small" text="Une installation de panneaux solaires en toute simplicité"></TitleAtom>
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="Monabee s’occupe de tout : étude de faisabilité technique à votre domicile, calcul de la rentabilité des panneaux photovoltaïques, démarches administratives, pose et configuration de vos panneaux solaires."></ParagraphAtom>
       


        <TitleAtom [mobileSize]="16" color="light" weight="semi-bold" size="tiny" text="Monabee est certifiée Quali Pv RGE & IRVE :"></TitleAtom>
       
        <div>
          <div class="offer-certification">
            <Image width="91px" type="image" image="certification-RGE"></Image>
            <div>
              <Image width="24px" type="icon" image="check-yellow"></Image>
              <ParagraphAtom color="light" size="intermediate" weight="regular" text="Certification pour la pose de panneaux"></ParagraphAtom>
            </div>
          </div>
          <div class="offer-certification">
            <Image width="81px" type="image" image="certification-IRVE-light"></Image>
            <div>
              <Image width="24px" type="icon" image="check-yellow"></Image>
              <ParagraphAtom color="light" size="intermediate" weight="regular" text="Habilitation pour l’installation d’une borne de recharge"></ParagraphAtom>
            </div>
          </div>
        </div>
      </div>
      <ContactMessage [hideRadioBtn]="true" [disableUserTypeChange]="true" title="Vous souhaitez une installation solaire pour votre maison ?" btnText="Obtenir mon devis" userType="particulier"></ContactMessage>
<!--      <div class="offer-banner"></div>-->
    </div>

  </div>

  <!-- ADVANTAGES -->
  <div id="produceAdvantages" class="offer-advantages">
    <TitleAtom [mobileSize]="22" type="h2" color="dark" align="center" size="x-large" weight="semi-bold" text="Les avantages de la production d’énergie solaire avec Monabee"></TitleAtom>
    <ul>
      <li>
        <div class="offer-advantages-safe">
          <div class="offer-success">
            <ParagraphAtom color="dark" weight="bold" size="intermediate" text="Service unique sur le marché"></ParagraphAtom>
          </div>
          <TitleAtom [mobileSize]="18" type="p" color="dark" weight="semi-bold" size="large" text="Sécurisez votre production d’énergie solaire grâce à Securee’z"></TitleAtom>
          <ul>
            <li>
              <div class="offer-title">
                <span class="point-success"></span>
                <ParagraphAtom color="dark" weight="bold" size="intermediate" text="30 ans de garantie"></ParagraphAtom>
              </div>

              <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Garantie pièce main d’œuvre et déplacement jusqu’à 30 ans (déplacement offert pour toutes les interventions curatives pendant la durée de garantie du matériel)"></ParagraphAtom>
            </li>
            <li>
              <div class="offer-title">
                <span class="point-success"></span>
                <ParagraphAtom color="dark" weight="bold" size="intermediate" text="Bilan autoconsommation"></ParagraphAtom>
              </div>

 
              <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Obtenez des conseils personnalisés pour mieux rentabiliser votre installation"></ParagraphAtom>
            </li>
            <li>
              <div class="offer-title">
                <span class="point-success"></span>
                <ParagraphAtom color="dark" weight="bold" size="intermediate" text="Visite de contrôle"></ParagraphAtom>
              </div>
          
              <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Soyez assuré du bon fonctionnement de votre installation"></ParagraphAtom>
            </li>
            <li>
              <div class="offer-title">
                <span class="point-success"></span>
                <ParagraphAtom color="dark" weight="bold" size="intermediate" text="Surveillance proactive"></ParagraphAtom>
              </div>
              <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Soyez prévenu par nos équipes en cas d’alerte"></ParagraphAtom>
            </li>
          </ul>
        </div>
        <div class="offer-image">
          <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" imageCloudinary="offre-panneaux-solaires/zroautl6thmbkg8ang46.png"></Image>

        </div>
      </li>
      
      <li>
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" imageCloudinary="offre-panneaux-solaires/Offre_panneaux-solaires_vianney-fichet_pjpssv.png"></Image>
        <div class="offer-advantages-safe">
          <TitleAtom [mobileSize]="18" type="p" color="dark" weight="semi-bold" size="large" text="Economisez jusqu’à 50% sur votre facture d’électricité"></TitleAtom>
          <div>
            <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Il ne tient qu’à vous de faire des économies sur vos dépenses d’électricité : <strong>l’énergie solaire est verte</strong>, gratuite et inépuisable. Son utilisation, à travers vos panneaux photovoltaïques, vous permet de réduire l’achat d’électricité sur le réseau (fini les heures pleines, heures creuses !). Si vous consommez au bon moment (c’est-à-dire avec le soleil), vos équipements fonctionnent avec les rayons de soleil, pour 0 €."></ParagraphAtom>
          </div>
        </div>
      </li>
      <li>
        <div class="offer-advantages-safe">
          <TitleAtom [mobileSize]="18" type="h3" color="dark" weight="semi-bold" size="large" text="Devenez producteur et consommateur de votre propre énergie solaire"></TitleAtom>
          <div>
            <div>
              <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Avec votre <strong>installation de panneaux photovoltaïques</strong> en autoconsommation, vous ne serez plus inquiet des augmentations du prix de l’énergie. Être producteur, c’est connaître sa capacité de production d’électricité annuelle, et autoconsommer son énergie en temps réel. Vous avez la main pour consommer l'électricité dont vous avez besoin."></ParagraphAtom>
            </div>
          </div>
        </div>
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" imageCloudinary="offre-panneaux-solaires/Offre_panneaux-solaires_client_Lyonnet-Christophe_droit_image_ok_qidalq.jpg"></Image>
      </li>
      <li style="display: none;">
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" imageCloudinary="offre-panneaux-solaires/image_2_devenez_uhcecb.jpg"></Image>
        <div class="offer-advantages-safe">
          <TitleAtom [mobileSize]="18" type="h3" color="dark" weight="semi-bold" size="large" text="Réduisez votre empreinte carbone, participez à la transition écologique"></TitleAtom>
          <ParagraphAtom color="dark" weight="regular" size="medium" text="Un système photovoltaïque met 1 an pour rembourser sa dette énergétique (l’empreinte carbone de fabrication). La durée de vie d’une installation solaire peut aller jusqu’à 40 ans et plus. En remplaçant les énergies fossiles par des énergies renouvelables, vous participez à l’atteinte des objectifs du GIEC : diminuer de 43% les émissions de CO² en 2030, et limiter le réchauffement climatique à +2°. Dans cette démarche, vous proposez un avenir plus sain à la génération future."></ParagraphAtom>
          <div class="offer-warning">
            <Image type="icon" size="32px" image="warning-orange"></Image>
            <div>
              <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Le bâtiment représente 21% des émissions mondiales de gaz à effet de serre selon le <strong>GIEC*</strong>. Vous avez le pouvoir de changer cela."></ParagraphAtom>
            </div>
          </div>
          <ParagraphAtom color="dark" size="small" weight="regular" text="*Groupe d’experts intergouvernemental sur l’évolution du climat"></ParagraphAtom>
        </div>
      </li>
    </ul>
  </div>

  <!-- 5 STEPS -->
  <div id="produceSteps" class="offer-steps">
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-top-left" image="offers-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-top-right" image="offers-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-bottom-left" image="offers-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-bottom-right" image="offers-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-top-left" image="offers-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-top-right" image="offers-top-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-bottom-left" image="offers-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-bottom-right" image="offers-bottom-right-mobile"></Image>

    <TitleAtom class="offers-steps-title" [mobileSize]="22" align="center" type="h2" color="dark" weight="semi-bold" size="x-large" text="Votre installation de panneaux solaires en 5 étapes"></TitleAtom>
    <ParagraphAtom class="offer-steps-paragraph" align="center" color="dark" weight="regular" size="intermediate" text="Monabee vous accompagne sur chaque étape de votre projet d’installation de panneaux photovoltaïques."></ParagraphAtom>
    <ul>
      <li>
        <CardInline [style]="'minimal'" image="step-1" title="Prenez contact avec nos équipes" text="C’est rapide et totalement gratuit ☀️" btnText="Demander une étude gratuite" href="/contact"></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-2" title="L’étude technique" text="Nos Conseillers en Energie Solaire étudient votre projet et réalisent une étude de faisabilité complète : consommation d’électricité, toiture, environnement, tous les paramètres sont étudiés.️"></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-3" title="Devis sur-mesure" text="Vous recevez un rapport personnalisé, correspondant à l’estimation des besoins d’énergie solaire de votre foyer au kWc près. Nous calculons pour vous la rentabilité de votre installation photovoltaïque. Nous ne proposons pas de kit standard : avec Monabee, c’est toujours du sur-mesure."></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-4" title="Les démarches administratives️" text="Monabee réalise toutes les démarches avant et après l’installation de panneaux photovoltaïques."></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-5" title="L’installation et la configuration de votre matériel" text="Nous vous conseillons sur la meilleure utilisation de vos panneaux solaires afin d’atteindre l’autonomie électrique grâce au solaire. Nous sommes certifiés QUALI PV RGE. C’est la garantie d’une installation solaire posée dans les règles de l’art, afin de bénéficier des primes solaires ou du Crédit d’impôt."></CardInline>
      </li>
    </ul>
    <ButtonAtom size="default" fragmentLink="offer-produce-form" [style]="'stroked'" color="light" text="Devis pour panneaux solaires" (click)="scrollTop()"></ButtonAtom>
  </div>

  <!-- SETUP -->
  <div id="produceSetup" class="offer-setup">
    <TitleAtom [mobileSize]="22" align="center" type="p" color="dark" weight="semi-bold" size="x-large" text="Tout ce qui compose mon installation solaire avec Optimee'z"></TitleAtom>

    <!-- STEP 1 -->
    <div class="slider-step1" *ngIf="this.stepCount === 1">
 
    <ng-lottie
      [width]="isMobile ? '100%' : '1220px'"
      [options]="{path: isMobile ? 'assets/animations/panneau-solaire-1-mobile/animation.json' : 'assets/animations/panneau-solaire-1/animation.json'}">
    </ng-lottie>

    <div class="slider-container">


      <div class="slider-title">
        <div class="slider-btn-left">
          <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
        </div>
        <TitleAtom align="center" type="h3" color="dark" size="large" weight="semi-bold" text="Panneaux solaires"></TitleAtom>
        <div class="slider-btn-right">
          <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
        </div>
  
      </div>

    </div>
    <div class="slider-content">
      <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" text="Nous installons des panneaux photovoltaïques de la marque Dualsun. En parallèle, nous travaillons avec SMA, Fronius et Emphase pour l’onduleur qui accompagne vos panneaux. Les panneaux solaires et l’onduleur sont garantis jusqu’à 25 ans."></ParagraphAtom>
    </div>
 
    <ul>
      <li>
        <a href="javascript:void(0);" (click)="goToStep(1)">
          <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" (click)="goToStep(2)">
          <Image type="icon" width="8px" image="ellipsis-grey"></Image>
        </a>
      </li>
      <li>
        <a href="javascript:void(0);" (click)="goToStep(3)">
          <Image type="icon" width="8px" image="ellipsis-grey"></Image>
        </a>
      </li>
    </ul>
  </div>

    <!-- STEP 3 -->
    <div class="slider-step1" *ngIf="this.stepCount === 2">
      <ng-lottie
        [width]="isMobile ? '100%' : '1220px'"
        [options]="{path: isMobile ? 'assets/animations/panneau-solaire-2-mobile/animation.json' : 'assets/animations/panneau-solaire-2/animation.json'}">
      </ng-lottie>
 
      <div class="slider-container">


        <div class="slider-title">
          <div class="slider-btn-left">
            <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
          </div>
          <TitleAtom align="center" type="h3" color="dark" size="large" weight="semi-bold" text="Assistant lumineux et application Optimee'z"></TitleAtom>
          <div class="slider-btn-right">
            <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
          </div>
    
        </div>
  
      </div>

      <div class="slider-content">
        <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" text="L’Assistant vous indique en temps réel (grâce à un système de couleurs), l’état de production de votre installation. L’application vous aide à piloter vos appareils et vous partage des rapports de  votre production d’énergie."></ParagraphAtom>
      </div>

      <ul>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(1)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(2)">
            <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(3)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
      </ul>
    </div>

    <!-- STEP 2 -->
    <div class="slider-step1" *ngIf="this.stepCount === 3">
      <ng-lottie
        [width]="isMobile ? '100%' : '1220px'"
        [options]="{path: isMobile ? 'assets/animations/panneau-solaire-3-mobile/animation.json' : 'assets/animations/panneau-solaire-3/animation.json'}">
      </ng-lottie>

      <div class="slider-container">


        <div class="slider-title">
          <div class="slider-btn-left">
            <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
          </div>
          <TitleAtom align="center" type="h3" color="dark" size="large" weight="semi-bold" text="Borne de recharge"></TitleAtom>
          <div class="slider-btn-right">
            <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
          </div>
    
        </div>
  
      </div>

      <div class="slider-content">
        <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" text="Avec une puissance de 22 kW, la recharge de votre véhicule électrique se fait jusqu’à 10 fois plus rapidement qu’avec une prise classique. Nous proposons 3 modes de recharge : mode eco (100% énergie verte), mode mixte (énergie verte + énergie du réseau), mode planifié (plages horaires définies)."></ParagraphAtom>
      </div>

      <ul>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(1)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(2)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(3)">
            <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
          </a>
        </li>
      </ul>
      
    </div>
  </div>

  <!-- PRO -->
  <div id="producePro" class="offer-pro"  style="display: none;">
    <div>
      <TitleAtom [mobileSize]="22" color="light" weight="semi-bold" size="x-large" text="Vous êtes un professionnel ?"></TitleAtom>
      <ParagraphAtom color="light" size="intermediate" weight="regular" text="En investissant dans une installation solaire, vous apportez de la visibilité à votre budget énergétique. Il vous permet de limiter les risques financiers liés à l’énergie."></ParagraphAtom>
      <div>
        <ButtonAtom size="default" [style]="'basic'" color="yellow" text="Demander un devis" (click)="hideContactModal = false"></ButtonAtom>
        <ButtonAtom size="default" [style]="'stroked'" color="light" text="Découvrir l'offre" href="/installer-panneaux-solaires-professionnels" ></ButtonAtom>
      </div>
    </div>

    <Image borderRadius="radius" type="image" width="500px" mobileWidth="100%" imageCloudinary="offre-panneaux-solaires/image_4_pro_hidyyd.jpg"></Image>
  </div>

  <!-- FAQ -->
  <div id="produceFaq" class="faq-container">
    <TitleAtom align="center" [mobileSize]="16" size="tiny" weight="semi-bold" color="orange" text="FAQ"></TitleAtom>
    <TitleAtom [mobileSize]="22" type="h2" size="x-large" weight="semi-bold" color="dark" text="Questions fréquentes"></TitleAtom>
    <ul>
      <li *ngFor="let faq of faqs">
        <Faq title="{{ faq.data.title }}" [text]="faq.data.body"></Faq>
      </li>
    </ul>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>

</div>

<ContactMessage title="Demander un devis gratuitement" btnText="Demander un devis gratuitement" userType="particulier" type="modal" [(isHidden)]="hideContactModal"></ContactMessage>
