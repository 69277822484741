<div class="offer-charging-container">
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>

  <!-- HEROBANNER -->
  <div class="offer-herobanner">

    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb color="light" [links]="[{title: 'Recharger mon véhicule électrique'}]"></Breadcrumb>
    </div>

    <div id="offer-charging-form" class="offer-herobanner-content">
      <div id="chargingOffer" class="content">
        <TitleAtom [mobileSize]="18" color="yellow" weight="semi-bold" size="small" text="Recharger mon véhicule électrique au solaire­"></TitleAtom>
        <TitleAtom type="h1" color="light" weight="semi-bold" size="x-large" text="Installation d'une borne de recharge solaire pour voiture électrique chez soi"></TitleAtom>
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="Votre installation de panneaux solaires reliée à une borne de recharge solaire vous permet de <strong>recharger votre voiture électrique (et votre maison)</strong> avec une <strong>énergie verte et gratuite</strong>. En rechargeant votre véhicule à l’énergie solaire, vous roulez gratuitement. De plus, l’installation d’une borne de recharge pour voiture électrique chez soi, vous participer à la transition écologique."></ParagraphAtom>
        <div class="glass-text">
          <ParagraphAtom color="light" weight="regular" size="intermediate" text="Selon le <strong>GIEC*</strong>, l’électrification des usages joue un rôle essentiel, à condition de produire de l’électricité bas-carbone. La baisse de la demande en énergie et en matériaux est essentielle pour réduire les émissions. Il est possible de réduire les besoins de 45% d’ici 2050 par l’efficacité énergétique"></ParagraphAtom>
        </div>
        <ParagraphAtom color="light" weight="regular" size="small" text="*Groupe d’experts intergouvernemental sur l’évolution du climat"></ParagraphAtom>
        <TitleAtom [mobileSize]="16" color="light" weight="semi-bold" size="tiny" text="Monabee est certifiée Quali Pv RGE & IRVE :"></TitleAtom>
        <div>
          <div class="offer-certification">
            <Image width="91px" type="image" image="certification-RGE"></Image>
            <div>
              <Image width="24px" type="icon" image="check-yellow"></Image>
              <ParagraphAtom color="light" size="intermediate" weight="regular" text="Certification pour la pose de panneaux"></ParagraphAtom>
            </div>
          </div>
          <div class="offer-certification">
            <Image width="81px" type="image" image="certification-IRVE-light"></Image>
            <div>
              <Image width="24px" type="icon" image="check-yellow"></Image>
              <ParagraphAtom color="light" size="intermediate" weight="regular" text="Habilitation pour l’installation d’une borne de recharge"></ParagraphAtom>
            </div>
          </div>
        </div>
      </div>
      <ContactMessage [hideRadioBtn]="true" [disableUserTypeChange]="true" title="Vous souhaitez une installation solaire pour votre maison ?" btnText="Obtenir mon devis" userType="particulier"></ContactMessage>
    </div>

  </div>

  <!-- ADVANTAGES -->
  <div id="chargingAdvantages" class="offer-advantages">
    <TitleAtom [mobileSize]="22" type="h2" align="center" color="dark" size="x-large" weight="semi-bold" text="Les avantages d’une borne de recharge solaire pour voiture électrique chez soi"></TitleAtom>
    <ul>
      <li>
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" imageCloudinary="offre-borne-solaire/image_1_economisez_ujddnn.jpg"></Image>
        <div class="offer-advantages-safe">
          <TitleAtom [mobileSize]="18" type="h3" color="dark" weight="semi-bold" size="large" text="Economisez en roulant pour 0 €"></TitleAtom>
          <div>
              <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Notre <strong>borne de recharge solaire</strong> vous permet d'utiliser l'énergie produite par vos panneaux photovoltaïques pour alimenter votre voiture électrique. Vous entrez dans un système <strong>d’autoconsommation</strong>."></ParagraphAtom>
          </div>
          <div>
              <ParagraphAtom color="dark" weight="regular" size="intermediate" text="<strong>Energie solaire + voiture électrique</strong>, le duo gagnant pour faire des économies sur sa facture d'électricité et protéger la planète."></ParagraphAtom>
          </div>
          <div>
              <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Notre <a href='domotique-solaire-monitoring-photovoltaique/'><strong><u>solution de monitoring</u></strong></a> vous permet de connaître à tout moment votre production d’énergie solaire disponible, afin de recharger votre véhicule électrique gratuitement aux « heures solaires »."></ParagraphAtom>
          </div>
        </div>
      </li>
      <li>
        <div class="offer-advantages-safe">
          <TitleAtom [mobileSize]="18" type="h3" color="dark" weight="semi-bold" size="large" text="Roulez avec une énergie solaire, 100% verte"></TitleAtom>
          <div>
            <ParagraphAtom color="dark" weight="regular" size="intermediate" text="L’énergie solaire n’émet pas de CO² (contrairement aux énergies fossiles), et possède un faible impact environnemental : les panneaux photovoltaïques se recyclent à 95% !"></ParagraphAtom>
            <div class="offer-warning">
              <Image type="icon" size="32px" image="warning-orange"></Image>
              <div>
                  <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Face à l'augmentation de la consommation électrique en France <strong>(+35% d'ici 2050 - RTE)</strong>, il est essentiel d'augmenter notre efficacité énergétique."></ParagraphAtom>
              </div>
            </div>
          </div>
        </div>
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" imageCloudinary="offre-borne-solaire/image_2_roulez_xntdwd.jpg"></Image>
      </li>
      <li>
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" imageCloudinary="offre-borne-solaire/Offre_borne-solaire_recharge-VE-large_qrfyg3.jpg"></Image>
        <div class="offer-advantages-safe">
          <TitleAtom [mobileSize]="18" type="h3" color="dark" weight="semi-bold" size="large" text="Rechargez simplement et rapidement votre voiture électrique avec la borne solaire"></TitleAtom>
          <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Avec une puissance de 22 kW, la recharge de votre voiture électrique, sur une borne de recharge solaire, se fait jusqu’à 10 fois plus rapidement qu’avec une prise électrique classique. Simplifiez-vous la vie avec la borne de recharge solaire à domicile. Rechargez quand vous le souhaitez, sans interrompre vos tâches quotidiennes."></ParagraphAtom>

          <div class="advantage-list">
            <div class="list-item">
              <div>
                <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="•"></ParagraphAtom>
              </div>
              <div>
                <div>
                  <ParagraphAtom color="dark" weight="regular" size="intermediate" text="<strong>Mode eco</strong> - Recharge de votre voiture électrique, 100% à l'énergie verte - Roulez pour 0€"></ParagraphAtom>
                </div>
              </div>
            </div>
            <div class="list-item">
              <div>
                <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="•"></ParagraphAtom>
              </div>
              <div>
                  <ParagraphAtom color="dark" weight="regular" size="intermediate" text="<strong>Mode mixte</strong> - Recharge de votre voiture électrique avec l'énergie verte et l'énergie du réseau"></ParagraphAtom>
              </div>
            </div>
            <div class="list-item">
              <div>
                <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="•"></ParagraphAtom>
              </div>
              <div>
                  <ParagraphAtom color="dark" weight="regular" size="intermediate" text="<strong>Mode planifié</strong> - Recharge de votre voiture électrique sur les plages horaires définies"></ParagraphAtom>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <!-- 5 STEPS -->
  <div id="chargingSteps" class="offer-steps">
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-top-left" image="offers-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-top-right" image="offers-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-bottom-left" image="offers-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-bottom-right" image="offers-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-top-left" image="offers-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-top-right" image="offers-top-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-bottom-left" image="offers-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-bottom-right" image="offers-bottom-right-mobile"></Image>

    <TitleAtom class="offers-steps-title" [mobileSize]="22" align="center" type="h2" color="dark" weight="semi-bold" size="x-large" text="Votre installation de panneaux solaires en 5 étapes"></TitleAtom>
    <ParagraphAtom class="offer-steps-paragraph" align="center" color="dark" weight="regular" size="intermediate" text="Monabee vous accompagne sur chaque étape de votre projet d’installation de panneaux photovoltaïques."></ParagraphAtom>
    <ul>
      <li>
        <CardInline [style]="'minimal'" image="step-1" title="Prenez contact avec nos équipes" text="C’est rapide et totalement gratuit ☀️" btnText="Demander une étude gratuite" href="/contact"></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-2" title="L’étude technique" text="Nos Conseillers en Energie Solaire réalisent une étude de faisabilité de votre projet d'installation solaire avec borne de recharge électrique à domicile."></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-3" title="Devis sur-mesure" text="Nous calculons la rentabilité de votre installation photovoltaïque + borne de recharge. Vous recevez ensuite un rapport personnalisé, correspondant à l’estimation des besoins d’énergie solaire de votre foyer au kWc près."></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-4" title="Les démarches administratives️" text="Monabee réalise toutes les démarches avant et après l’installation de panneaux photovoltaïques."></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-5" title="L’installation et la configuration de votre matériel" text="Nous vous conseillons sur la meilleure utilisation de vos panneaux solaires afin d’atteindre l’autonomie électrique grâce au solaire. Nous sommes certifiés QUALI PV RGE. C’est la garantie d’une installation solaire posée dans les règles de l’art, afin de bénéficier des primes solaires ou du Crédit d’impôt."></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-6" title="Rechargez votre voiture électrique au solaire" text="Nous configurerons votre installation de panneaux solaires adaptée à vos besoins en fonction du nombre de véhicules à recharger. Et ce, afin de tendre vers l’autonomie électrique grâce au solaire."></CardInline>
      </li>
    </ul>
    <ButtonAtom size="default" fragmentLink="offer-charging-form" [style]="'stroked'" color="light" text="Devis pour panneaux solaires" (click)="scrollTop()"></ButtonAtom>
  </div>


    <!-- SETUP -->
    <div id="chargingSetup" class="offer-setup">
      <TitleAtom [mobileSize]="22" align="center" type="p" color="dark" weight="semi-bold" size="x-large" text="Tout ce qui compose mon installation solaire avec Optimee'z"></TitleAtom>
  
      <!-- STEP 1 -->
      <div class="slider-step1" *ngIf="this.stepCount === 1">
   
      <ng-lottie
        [width]="isMobile ? '100%' : '1220px'"
        [options]="{path: isMobile ? 'assets/animations/panneau-solaire-1-mobile/animation.json' : 'assets/animations/panneau-solaire-1/animation.json'}">
      </ng-lottie>
  
      <div class="slider-container">
  
  
        <div class="slider-title">
          <div class="slider-btn-left">
            <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
          </div>
          <TitleAtom align="center" type="h3" color="dark" size="large" weight="semi-bold" text="Panneaux solaires"></TitleAtom>
          <div class="slider-btn-right">
            <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
          </div>
    
        </div>
  
      </div>
      <div class="slider-content">
        <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" text="Nous installons des panneaux photovoltaïques de la marque Dualsun. En parallèle, nous travaillons avec SMA, Fronius et Emphase pour l’onduleur qui accompagne vos panneaux. Les panneaux solaires et l’onduleur sont garantis jusqu’à 25 ans."></ParagraphAtom>
      </div>
   
      <ul>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(1)">
            <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(2)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(3)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
      </ul>
    </div>
  
      <!-- STEP 3 -->
      <div class="slider-step1" *ngIf="this.stepCount === 2">
        <ng-lottie
          [width]="isMobile ? '100%' : '1220px'"
          [options]="{path: isMobile ? 'assets/animations/panneau-solaire-2-mobile/animation.json' : 'assets/animations/panneau-solaire-2/animation.json'}">
        </ng-lottie>
   
        <div class="slider-container">
  
  
          <div class="slider-title">
            <div class="slider-btn-left">
              <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
            </div>
            <TitleAtom align="center" type="h3" color="dark" size="large" weight="semi-bold" text="Assistant lumineux et application Optimee'z"></TitleAtom>
            <div class="slider-btn-right">
              <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
            </div>
      
          </div>
    
        </div>
  
        <div class="slider-content">
          <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" text="L’Assistant vous indique en temps réel (grâce à un système de couleurs), l’état de production de votre installation. L’application vous aide à piloter vos appareils et vous partage des rapports de  votre production d’énergie."></ParagraphAtom>
        </div>
  
        <ul>
          <li>
            <a href="javascript:void(0);" (click)="goToStep(1)">
              <Image type="icon" width="8px" image="ellipsis-grey"></Image>
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" (click)="goToStep(2)">
              <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" (click)="goToStep(3)">
              <Image type="icon" width="8px" image="ellipsis-grey"></Image>
            </a>
          </li>
        </ul>
      </div>
  
      <!-- STEP 2 -->
      <div class="slider-step1" *ngIf="this.stepCount === 3">
        <ng-lottie
          [width]="isMobile ? '100%' : '1220px'"
          [options]="{path: isMobile ? 'assets/animations/panneau-solaire-3-mobile/animation.json' : 'assets/animations/panneau-solaire-3/animation.json'}">
        </ng-lottie>
  
        <div class="slider-container">
  
  
          <div class="slider-title">
            <div class="slider-btn-left">
              <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
            </div>
            <TitleAtom align="center" type="h3" color="dark" size="large" weight="semi-bold" text="Borne de recharge"></TitleAtom>
            <div class="slider-btn-right">
              <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
            </div>
      
          </div>
    
        </div>
  
        <div class="slider-content">
          <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" text="Avec une puissance de 22 kW, la recharge de votre véhicule électrique se fait jusqu’à 10 fois plus rapidement qu’avec une prise classique. Nous proposons 3 modes de recharge : mode eco (100% énergie verte), mode mixte (énergie verte + énergie du réseau), mode planifié (plages horaires définies)."></ParagraphAtom>
        </div>
  
        <ul>
          <li>
            <a href="javascript:void(0);" (click)="goToStep(1)">
              <Image type="icon" width="8px" image="ellipsis-grey"></Image>
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" (click)="goToStep(2)">
              <Image type="icon" width="8px" image="ellipsis-grey"></Image>
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" (click)="goToStep(3)">
              <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
            </a>
          </li>
        </ul>
        
      </div>
    </div>
 

  <!-- PRO -->
  <div id="chargingPro" class="offer-pro" style="display: none;">
    <div>
      <TitleAtom [mobileSize]="22" color="light" weight="semi-bold" size="x-large" text="Vous êtes un professionnel ?"></TitleAtom>
      <ParagraphAtom color="light" size="intermediate" weight="regular" text="Grâce à une installation photovoltaïque et une borne de recharge, vous réduisez votre facture d’électricité et les risques financiers liés à la hausse du prix de l’électricité. Vous rechargez vos voitures électriques en toute sérénité."></ParagraphAtom>
      <div>
        <ButtonAtom size="default" [style]="'basic'" color="yellow" text="Demander un devis" (click)="hideContactModal = false"></ButtonAtom>
        <ButtonAtom size="default" [style]="'stroked'" color="light" text="Découvrir l'offre" href="/installer-panneaux-solaires-professionnels"></ButtonAtom>
      </div>
    </div>

    <Image borderRadius="radius" type="image" width="500px" mobileWidth="100%" imageCloudinary="offre-borne-solaire/image_4_pro_kkusnj.jpg"></Image>
  </div>

  <!-- FAQ -->
  <div id="chargingFaq" class="faq-container">
    <TitleAtom align="center" [mobileSize]="16" size="tiny" weight="semi-bold" color="orange" text="Questions Fréquentes sur l'installation de panneaux solaires chez soi"></TitleAtom>
    <TitleAtom [mobileSize]="22" type="h2" size="x-large" weight="semi-bold" color="dark" text="Questions fréquentes"></TitleAtom>
    <ul>
      <li *ngFor="let faq of faqs">
        <Faq title="{{ faq.data.title }}" [text]="faq.data.body"></Faq>
      </li>
    </ul>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>

</div>

<ContactMessage title="Demander un devis gratuitement" btnText="Demander un devis gratuitement" userType="particulier" type="modal" [(isHidden)]="hideContactModal"></ContactMessage>
