<button *ngIf="href === ''" [ngClass]="classes" [disabled]="isDisabled" (click)="type === 'share' ? getShare() : ''">
  <ng-container>
    <img *ngIf="icon && !href" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}" >
    <span [innerHTML]="safeHtml"></span>
    <img *ngIf="iconRight && !href" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}" >
  </ng-container>
</button>

<ng-container *ngIf="target === '_blank'">
  <a *ngIf="href !== ''" [target]="target"  [ngClass]="classes" [href]="href">
    <img *ngIf="icon" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}" >
    {{text}}
    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}" >
  </a>
</ng-container>

<ng-container *ngIf="target !== '_blank'">
  <a *ngIf="href !== '' && !href.startsWith('tel:')"  [ngClass]="classes" [routerLink]="'/' + href" [queryParams]="queryParams">
    <img *ngIf="icon" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}" >
    {{text}}
    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}" >
  </a>
  <a *ngIf="href !== '' && href.startsWith('tel:')"  [ngClass]="classes" [href]="href">
    <img *ngIf="icon" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}" >
    {{text}}
    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}" >
  </a>
</ng-container>

<div class="share-modal" *ngIf="shareIsHidden === false">
  <ParagraphAtom color="dark" size="intermediate" weight="semi-bold" text="Partager"></ParagraphAtom>
  <!-- close btn -->
  <button class="close" (click)="shareIsHidden = true">
    <Image type="icon" image="close-dark" width="24px" mobileWidth="24px"></Image>
  </button>
  <ul>
    <!-- COPY -->
    <li>
      <a href="javascript:void(0)" (click)="copyToClipboard()">
        Copier le lien
      </a>
    </li>
    <!-- EMAIL -->
    <li>
      <a href="mailto:?subject={{shareText}}&body={{shareUrl}}" target="_blank">
        Email
      </a>
    </li>
    <!-- FACEBOOK -->
    <li>
      <a href="https://www.facebook.com/sharer/sharer.php?u={{shareUrl}}" target="_blank">
        Facebook
      </a>
    </li>
    <!-- TWITTER -->
    <li>
      <a href="https://twitter.com/intent/tweet?text={{shareText}}&url={{shareUrl}}" target="_blank">
        Twitter
      </a>
    </li>
    <!-- WHATSAPP -->
    <li>
      <a href="https://api.whatsapp.com/send?text={{shareText}}%20{{shareUrl}}" target="_blank">
        Whatsapp
      </a>
    </li>
  </ul>
</div>
