import {Component, Inject, OnInit, HostListener, PLATFORM_ID} from '@angular/core';
import { Router } from '@angular/router';
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'Navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  /* To check if navbar is sticky */
  public sticky: boolean = false;

  /* The current url */
  public currentUrl: string = '';

  /* MOBILE DECLARATIONS */
  public menuIsOpen: boolean = false;
  public offersIsOpen: boolean = false;
  public informedIsOpen: boolean = false;
  public aboutIsOpen: boolean = false;

  /** The width and height of the window user */
  public isMobile = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.currentUrl = this.router.url;

    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.isMobile = window.innerWidth < 1024;
    }
  }

  /**
   * Check scroll for sticky navbar
   */
  @HostListener('window:scroll', ['$event'])
  handleScroll(){
    const windowScroll = window.pageYOffset;

    if (windowScroll >= 40){
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  /* MOBILE MENU */
  public openMenu(): void {
    this.menuIsOpen = true;
    document.body.classList.add('modal-open');
  }

  public closeMenu(): void {
    this.menuIsOpen = false;
    document.body.classList.remove('modal-open');
  }

  public openOffers(): void {
    this.offersIsOpen = !this.offersIsOpen;
  }

  public openInformed(): void {
    this.informedIsOpen = !this.informedIsOpen;
  }

  public openAbout(): void {
    this.aboutIsOpen = !this.aboutIsOpen;
  }
}
