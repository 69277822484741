import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';

declare const google: any;

@Directive({
  selector: '[postalCodeAutocomplete]'
})
export class PostalCodeAutocompleteDirective implements OnInit {
  @Input() inputElement!: HTMLInputElement;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  private autocomplete: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {
      this.autocomplete = new google.maps.places.Autocomplete(this.inputElement, {
        fields: ['address_components', 'geometry', 'formatted_address'],
        types: ['(regions)'],
        componentRestrictions: { country: 'fr' }
      });

      this.autocomplete.addListener('place_changed', () => {
        setTimeout(() => {
          const place = this.autocomplete.getPlace();

          const postal_code = place.address_components.find(
            (component: any) => component.types.indexOf('postal_code') !== -1
          )?.short_name;

          this.onSelect.emit(postal_code);
        }, 0);
      });

      google.maps.event.addDomListener(this.inputElement, 'input', () => {
        const inputPostalCode = this.inputElement.value;
        if (inputPostalCode.length >= 2) {
          this.autocomplete.setOptions({
            input: `postal_code:${inputPostalCode}`,
          });
        }
      });
    }
  }
}
