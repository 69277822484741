<div class="cms-html-renderer-container">
    <ng-container *ngFor="let d of data; let i = index">

        <!-- Display the right component -->
        <TitleAtom *ngIf="d.type === 'heading4'"  margin="auto" type="h4" text="{{ d.text }}" align="left" color="{{ colorTitle !== '' ? colorTitle : 'dark'}}" size="medium" weight="semi-bold"></TitleAtom>
        <TitleAtom *ngIf="d.type === 'heading3'"  margin="auto" type="h3" text="{{ d.text }}" align="left" color="{{ colorTitle !== '' ? colorTitle : 'dark'}}" size="intermediate" weight="semi-bold"></TitleAtom>
        <TitleAtom *ngIf="d.type === 'heading2'" [attr.id]="d.id" margin="auto" type="h2" text="{{ d.text }}" align="left" color="{{ colorTitle !== '' ? colorTitle : 'dark'}}" size="large" weight="semi-bold"></TitleAtom>
        <ParagraphAtom *ngIf="d.type === 'paragraph' && !isTable(d.text) && !isHeadline(d.text)" size="intermediate" weight="regular" align="left" text="{{ cms.textStyle(d) }}"></ParagraphAtom>
        <Image *ngIf="d.type === 'image'" imageExternal="{{ d.url }}" alt="{{ d.alt }}" width="800px" mobileWidth="100%" borderRadius="radius"></Image>

        <!-- Table -->
        <div *ngIf="isTable(d.text)" class="table" [innerHTML]="getTable(d.text)"></div>

        <!-- Headline -->
        <div *ngIf="isHeadline(d.text)" class="headline">
            <h4>
                <img src="assets/images/icons/chat.svg" alt="chat" >
                {{ getHeadline(d.text).headline_title }}
            </h4>
            <ng-container *ngFor="let b of getHeadline(d.text).headline_body">
                <p [innerHTML]="cms.textStyle(b)"></p>
            </ng-container>
        </div>

        <!-- Normal list -->
        <ul *ngIf="d.type === 'list-item' && (i - 1 < 0 || data[i - 1].type !== 'list-item')">
            <li *ngFor="let item of getListItem(i, d)">
                <ng-container *ngIf="item.spans && item.spans.length > 0 && item.spans[0].type === 'hyperlink'">
                    <a href="{{ item.spans[0].data.url }}" target="_blank">{{ item.text }}</a>
                </ng-container>

                <ng-container *ngIf="item.spans && item.spans.length > 0 && item.spans[0].type === 'strong'">
                    <p [innerHTML]="cms.textStyle(item)"></p>
                </ng-container>

                <ng-container *ngIf="item.spans == undefined || item.spans.length == 0 ">
                    <ng-container *ngIf="listGenerateId.includes(generateId(item.text,true))">
                        <a href="javascript:void(0)" (click)="scrollToId(generateId(item.text,true))">{{ item.text }}</a>
                    </ng-container>
                    <ng-container *ngIf="!listGenerateId.includes(generateId(item.text,true))">
                        {{ item.text }}
                    </ng-container>
                </ng-container>
            </li>
        </ul>





        <!-- Order list -->
        <!--    <ol *ngIf="d.type === 'o-list-item' && data[i-1].type !== 'o-list-item'">-->
        <ol *ngIf="d.type === 'o-list-item' && (i-1 < 0 || data[i-1].type !== 'o-list-item')">
            <li *ngFor="let item of getListItem(i, d)">
                <ng-container *ngIf="listGenerateId.includes(generateId(item.text,true))">
                    <a href="javascript:void(0)" (click)="scrollToId(generateId(item.text,true))">{{ item.text }}</a>
                </ng-container>
                <ng-container *ngIf="!listGenerateId.includes(generateId(item.text,true))">
                    {{ item.text }}
                </ng-container>
            </li>
        </ol>

        <!-- Add space between component -->
        <div class="separator"></div>
    </ng-container>
</div>

