<div *ngIf="isMobile != true" class="announcement-component" [ngStyle]="{'background-color': data?.data?.background_color}">
  <div class="announcement-wrapper">

    <!-- EXTERNAL LINK -->
    <div class="cursor" *ngIf="this.data?.data?.external_link?.url" (click)="openLink()">
      <ParagraphAtom text="{{ data?.data?.text }}" color="light" size="intermediate" weight="regular"></ParagraphAtom>
      
    </div>

    <!-- INTERNAL LINK -->
    <div class="cursor" *ngIf="this.data?.data?.internal_link?.url && !this.data?.data?.internal_link?.url?.includes('#')" (click)="navigate()">
      <ParagraphAtom text="{{ data?.data?.text }}" color="light" size="intermediate" weight="regular"></ParagraphAtom>
    </div>

    <!-- ANCHOR LINK -->
    <div class="cursor" *ngIf="this.data?.data?.internal_link?.url?.includes('#')" (click)="navigateToAnchorLink()">
      <ParagraphAtom text="{{ data?.data?.text }}" color="light" size="intermediate" weight="regular"></ParagraphAtom>
    </div>

    <!-- NO LINK -->
    <div *ngIf="!this.data?.data?.internal_link!.url && !this.data?.data?.external_link?.url">
      <ParagraphAtom text="{{ data?.data?.text }}" color="light" size="intermediate" weight="regular"></ParagraphAtom>
    </div>

    <div>
      <ButtonAtom [style]="'clear'" href="tel:{{ data?.data?.phone_number }}" color="light" text="{{ data?.data?.phone_number }}" icon="phone-white"></ButtonAtom>
      <ButtonAtom [style]="'clear'" color="light" text="Espace client" icon="account" href="https://monabee.net" target="_blank"></ButtonAtom>
    </div>
  </div>
</div>

<div *ngIf="isMobile === true" class="announcement-component" [ngStyle]="{'background-color': data?.data?.background_color}">
  <div class="announcement-wrapper">
      <p class="paragraph-announcement">{{ data?.data?.text }}</p>
      <p class="paragraph-announcement">{{ data?.data?.text }}</p>
  </div>
</div>