<button *ngIf="!href || href === ''" [ngClass]="classes" [disabled]="isDisabled" (click)="type === 'share' ? getShare() : ''">
  <ng-container>
    <img *ngIf="icon && (!href || href === '')" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}" >
    <span [innerHTML]="safeHtml"></span>
    <img *ngIf="iconRight && (!href || href === '')" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}" >
  </ng-container>
</button>

<ng-container *ngIf="target === '_blank'">
  <a *ngIf="href && href !== ''" [target]="target" [ngClass]="classes" [href]="href">
    <img *ngIf="icon" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}" >
    {{text}}
    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}" >
  </a>
</ng-container>

<ng-container *ngIf="target !== '_blank'">
  <a *ngIf="href && href !== '' && !href.startsWith('tel:')" [ngClass]="classes" [routerLink]="'/' + href" [queryParams]="queryParams">
    <img *ngIf="icon" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}" >
    {{text}}
    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}" >
  </a>
  <a *ngIf="href && href !== '' && href.startsWith('tel:')" [ngClass]="classes" [href]="href">
    <img *ngIf="icon" [src]="'../../../assets/images/icons/' + icon + '.svg'" alt="{{icon}}" >
    {{text}}
    <img *ngIf="iconRight" [src]="'../../../assets/images/icons/' + iconRight + '.svg'" alt="{{iconRight}}" >
  </a>
</ng-container>
