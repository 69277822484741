<div class="offer-pro-container">
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>

  <!-- HEROBANNER -->
  <div class="offer-herobanner">

    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb color="light" [links]="[{title: 'Professionnels'}]"></Breadcrumb>
    </div>

    <div id="offer-pro-form" class="offer-herobanner-content">
      <div id="proOffer" class="content">
        <TitleAtom [mobileSize]="18" color="yellow" weight="semi-bold" size="small" text="Chefs d’entreprise, Artisans, PME, Agriculteurs , Industrie, Collectivités"></TitleAtom>
        <TitleAtom type="h1" color="light" weight="semi-bold" size="x-large" text="Maîtrisez votre budget énergétique"></TitleAtom>
      
      
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="La part de l'électricité dans la consommation énergétique va massivement augmenter dans tous les secteurs selon RTE. Les scénarios anticipent notamment une augmentation de 30% d'ici 2050 dans le secteur industriel. "></ParagraphAtom>

        <ParagraphAtom color="light" weight="regular" size="intermediate" text="72% des chefs d’entreprises disent être impactés fortement par la hausse des coûts de l’énergie. Monabee accompagne les entreprises dans la production, l’autoconsommation et la vente de surplus."></ParagraphAtom>
        <br>
        <TitleAtom [mobileSize]="16" color="light" weight="semi-bold" size="tiny" text="Monabee est certifiée Quali Pv RGE & IRVE :"></TitleAtom>
        <div>
          <div class="offer-certification">
            <Image width="91px" type="image" image="certification-RGE"></Image>
            <div>
              <Image width="24px" type="icon" image="check-yellow"></Image>
              <ParagraphAtom color="light" size="intermediate" weight="regular" text="Certification pour la pose de panneaux"></ParagraphAtom>
            </div>
          </div>
          <div class="offer-certification">
            <Image width="81px" type="image" image="certification-IRVE-light"></Image>
            <div>
              <Image width="24px" type="icon" image="check-yellow"></Image>
              <ParagraphAtom color="light" size="intermediate" weight="regular" text="Habilitation pour l’installation d’une borne de recharge"></ParagraphAtom>
            </div>
          </div>
        </div>
      </div>

      <ContactMessage [hideRadioBtn]="true" [disableUserTypeChange]="true" title="Vous souhaitez une installation et une borne solaire pour votre entreprise ?" btnText="Obtenir mon devis" userType="professionnel"></ContactMessage>
      <div class="offer-banner"></div>
    </div>

  </div>

  <!-- FORM VALIDATION MODAL -->
  <div *ngIf="this.formIsValidated === true" class="offer-form-validation-modal-container">
    <div class="offer-form-validation-modal">
      <div>
        <TitleAtom color="light" weight="semi-bold" size="x-heading" text="Merci !"></TitleAtom>
        <div class="close-btn">
          <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeValidateForm()"></ButtonAtom>
        </div>
      </div>
      <div>
        <ParagraphAtom color="dark" weight="regular" size="large" text="Votre demande de devis a bien été transférée à nos équipes Monabee. Vous allez rapidement être recontacté par l’un de nos conseillers."></ParagraphAtom>
        <ButtonAtom [style]="'basic'" color="yellow" text="Fermer" (click)="closeValidateForm()"></ButtonAtom>
      </div>
    </div>
  </div>

  <!-- ADVANTAGES -->
  <div id="proAdvantages" class="professional-advantages">
    <div id="advantageList" class="advantage-list">
      <div class="advantage-content">
        <div> 
          <div class="advantage-title">
            <TitleAtom type="h2" [mobileSize]="22" color="dark" align="center" size="large" weight="semi-bold" text="La valeur ajoutée de Monabee
            pour les professionnels"></TitleAtom>
          </div>
          <ul>
            <li>
              <div class="advantage-item">
                <div class="advantage-icon">
                  <Image type="icon" width="64px" image="piggy-bank-blue"></Image>
                </div>
                <div class="advantage-content">
                  <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Maîtrisez votre budget"></TitleAtom>
                  <ParagraphAtom color="dark"  size="medium" weight="regular" text="Effacez 40% de la consommation d'énergie et un ROI moyen de 5 ans sur toutes nos études."></ParagraphAtom>
                </div>
              </div>
            </li>
            <li>
              <div class="advantage-item">
                <div class="advantage-icon">
                  <Image type="icon" width="64px" image="security"></Image>
                </div>
                <div class="advantage-content">
                  <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Sécurisez le prix du kWh"></TitleAtom>
                  <ParagraphAtom color="dark" size="medium" weight="regular" text="Avec votre installation solaire, <b> vous produisez une énergie à prix fixe </b>. En parallèle, vous augmentez votre autonomie face au réseau électrique."></ParagraphAtom>
                </div>
              </div>
            </li>
            <li>
              <div class="advantage-item">
                <div class="advantage-icon">
                  <Image type="icon" width="64px" image="legal"></Image>
                </div>
                <div class="advantage-content">
                  <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Respectez la réglementation"></TitleAtom>
                  <ParagraphAtom color="dark" size="medium" weight="regular" text="Le décret tertiaire impose de <b> produire une énergie renouvelable et décarbonée </b> répondant aux nouvelles exigences."></ParagraphAtom>
                </div>
              </div>
            </li>
            <li>
              <div class="advantage-item">
                <div class="advantage-icon">
                  <Image type="icon" width="64px" image="garantee"></Image>
                </div>
                <div class="advantage-content">
                  <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Fidélisez vos salariés"></TitleAtom>
                  <ParagraphAtom color="dark"  size="medium" weight="regular" text="86% des français attendent des entreprises des solutions aux enjeux environnementaux et sociétaux."></ParagraphAtom>
                </div>
              </div>
            </li>
          </ul>
          
        </div>
      </div>
    </div>
  </div>
  

   <!-- OUR-SERVICES -->
   <div id="ourServices" class="our-services-container">
    <div id="serviceList" class="service-list">
      <div class="service-content">
        <div> 
          <div class="service-title">
            <TitleAtom type="h2" [mobileSize]="22" color="dark" align="center" size="large" weight="semi-bold" text="Nos prestations professionnelles"></TitleAtom>
          </div>
          <ul>
            <li>
              <div class="service-item"[routerLink]="'/professionnels/toiture/'">
                <Image width="288px"  mobileWidth="100%" type="image" image="toit-panneaux" borderRadius="radius"></Image>
              
                <div class="discover-link"  >
                  <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Toiture"></TitleAtom>
                  <Image type="icon" image="arrow-right-dark" width="6px" mobileWidth="6px"></Image>

                </div>
              </div>
            </li>
            <li>
              <div class="service-item" [routerLink]="'/professionnels/ombriere/'">
                <Image width="288px" mobileWidth="100%" type="image" image="ombriere-panneaux" borderRadius="radius"></Image>
          

                <div class="discover-link" >
                  <TitleAtom type="h3" color="dark"  size="medium" weight="semi-bold" text="Ombrière"></TitleAtom>
                  <Image type="icon" image="arrow-right-dark" width="6px" mobileWidth="6px"></Image>

                </div>
              </div>
            </li>
            <li>
              <div class="service-item" [routerLink]="'/professionnels/hangar-agricole/'">
                <Image width="288px" mobileWidth="100%" type="image" image="hangar-panneaux" borderRadius="radius"></Image>
                 

                <div class="discover-link" >
                  <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Hangar Agricole"></TitleAtom>
                  <Image type="icon" image="arrow-right-dark" width="6px" mobileWidth="6px"></Image>

                </div>
              </div>
            </li>
            <li>
              <div class="service-item"[routerLink]="'/professionnels/pose-sol/'">
                <Image width="288px" mobileWidth="100%" type="image" image="sol-panneaux" borderRadius="radius"></Image>

                <div class="discover-link"  >
                  <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Pose au sol"></TitleAtom>
                  <Image type="icon" image="arrow-right-dark" width="6px" mobileWidth="6px"></Image>

                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
   <!-- YOU-ARE -->
  <div id="youAre" class="you-are-container">
    <div id="youAreList" class="you-are-list">
      <div class="you-are-content">
        <div>
          <div class="you-are-title">
            <TitleAtom type="h2" [mobileSize]="22" color="dark" align="center" size="large" weight="semi-bold" text="Vous êtes"></TitleAtom>
            <ParagraphAtom color="dark" align="center" size="large" weight="regular" text="Selon l’Ademe, les <strong> dépenses énergétiques </strong> représentent de <strong> 5% à 20% des coûts </strong> pour les entreprises françaises."></ParagraphAtom>
          </div>
          <ul>
            <li>
              <div class="you-are-item">
                <Image type="icon" width="64px" image="company"></Image>
                <div class="you-are-details">
                  <TitleAtom [mobileSize]="16" type="h3" color="dark" size="medium" weight="semi-bold" text="Une entreprise"></TitleAtom>
                  <div class="discover-link" [routerLink]="'/professionnels/entreprise/'">
                    <ParagraphAtom color="dark" size="medium" weight="bold" text="Découvrir"></ParagraphAtom>
                    <Image type="icon" image="arrow-right-dark" width="6px" mobileWidth="6px"></Image>
                  </div>
                </div>
              </div>
              
            </li>
            <li>
              <div class="you-are-item">
                <Image type="icon" width="64px" image="farmer"></Image>
                <div class="you-are-details">
                  <TitleAtom [mobileSize]="16" type="h3" color="dark" size="medium" weight="semi-bold" text="Un agriculteur"></TitleAtom>
                  <div class="discover-link" [routerLink]="'/professionnels/agriculteur/'">
                    <ParagraphAtom color="dark" size="medium" weight="bold" text="Découvrir"></ParagraphAtom>
                    <Image type="icon" image="arrow-right-dark" width="6px" mobileWidth="6px"></Image>

                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="you-are-item">
                <Image type="icon" width="64px" image="collectivity"></Image>
                <div class="you-are-details">
                  <TitleAtom [mobileSize]="16" type="h3" color="dark" size="medium" weight="semi-bold" text="Une collectivité"></TitleAtom>
                   <div class="discover-link" [routerLink]="'/professionnels/collectivite/'">
                    <ParagraphAtom color="dark" size="medium" weight="bold" text="Découvrir"></ParagraphAtom>
                    <Image type="icon" image="arrow-right-dark" width="6px" mobileWidth="6px"></Image>

                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="you-are-item">
                <Image type="icon" width="64px" image="shop"></Image>
                <div class="you-are-details">
                  <TitleAtom [mobileSize]="16" type="h3" color="dark" size="medium" weight="semi-bold" text="Un commerce"></TitleAtom>
                  <div class="discover-link" [routerLink]="'/professionnels/commerce/'">
                    <ParagraphAtom color="dark" size="medium" weight="bold" text="Découvrir"></ParagraphAtom>
                    <Image type="icon" image="arrow-right-dark" width="6px" mobileWidth="6px"></Image>

                  </div>
                </div>
              </div>
            </li>            
          </ul>
        </div>
      </div>
    </div>
  </div>
  

  <div class="middle-btn-devis">
    <ButtonAtom [style]="'basic'" height="default" color="yellow" text="Obtenir mon devis" (click)="scrollToInquiry()"></ButtonAtom>
</div>

  <!-- ADDITIONAL SERVICES -->
  <div id="additionalServices" class="additional-services-container">
    <div class="additional-services-header">
      <TitleAtom type="h2" [mobileSize]="22" color="dark" align="center" size="large" weight="semi-bold" text="Nos services additionnels"></TitleAtom>
      <ParagraphAtom color="dark" size="medium" align="center" weight="regular" text="Ajoutez des services complémentaires à votre installation de panneaux solaires"></ParagraphAtom>
    </div>

    <div class="additional-services-content">
      <div *ngIf="stepCount == 1" class="slider-step1" (swipeleft)="onSwipeLeft()" (swiperight)="onSwipeRight()">
   

        <div class="slider-container">
   
          <div class="slider-content">
            <div class="slider-btn-left">
              <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
            </div>
              <div class="additional-services-image">
                <Image type="image" width="100%"  mobileWidth="344px" image="borne-recharge" borderRadius="radius"></Image>
              </div>
              <div class="additional-services-description">
                <div class="additional-services-title">
                  <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Bornes de recharge"></TitleAtom>
                </div>
                <div class="additional-services-subtitle">
                  <ParagraphAtom color="dark" size="medium" weight="regular" text="Rechargez votre flotte de véhicules électriques (collaborateurs, clients...) et répondez à la loi LOM (Loi d'Obligation de Mobilité)"></ParagraphAtom>
                </div>
                <ul>
                  <li>Associez votre borne de recharge à vos panneaux photovoltaïques pour aller plus loin dans votre démarche de production et consommation d’électricité verte.</li>
                  <li><strong>Réduisez votre facture: </strong> la recharge annuelle d’une flotte de 10 véhicules électriques coûte en moyenne 8 100 € à une entreprise.</li>
                  <li><strong>Facilitez la vie de vos collaborateurs et clients : </strong> 60% des français estiment qu’un accès à un point de recharge sur le lieu de travail est très important (source : Institut Kantar).</li>
                  <li><strong>Démontrez votre engagement écologique :</strong>  86% des français attendent des entreprises des solutions aux enjeux environnementaux et sociétaux (source : étude Kantar Sustainability Sector Index).</li>
                </ul>
              </div>
              <div class="slider-btn-right">
                <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
              </div>
          </div>
     
          <div class="slider-pagination">
            <ul>
              <li>
                <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
              </li>
              <li (click)="goToStep(2)">
                <Image type="icon" width="8px" image="ellipsis-grey"></Image>  
              </li>
            </ul>
          </div>
        </div>
        </div>


 
      <div *ngIf="stepCount == 2" class="slider-step1"  >


      <div class="slider-container">
 
        <div class="slider-content">
          <div class="slider-btn-left">
            <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
          </div>
            <div class="additional-services-image">
              <Image type="image" width="100%" image="borne-recharge" borderRadius="radius"></Image>
            </div>
            <div class="additional-services-description">
              <div class="additional-services-title">
                <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Bornes de recharge 2"></TitleAtom>
              </div>
              <div class="additional-services-subtitle">
                <ParagraphAtom color="dark" size="medium" weight="regular" text="Rechargez votre flotte de véhicules électriques (collaborateurs, clients...) et répondez à la loi LOM (Loi d'Obligation de Mobilité)"></ParagraphAtom>
              </div>
              <ul>
                <li>Associez votre borne de recharge à vos panneaux photovoltaïques pour aller plus loin dans votre démarche de production et consommation d’électricité verte.</li>
                <li><strong>Réduisez votre facture: </strong> la recharge annuelle d’une flotte de 10 véhicules électriques coûte en moyenne 8 100 € à une entreprise.</li>
                <li><strong>Facilitez la vie de vos collaborateurs et clients : </strong> 60% des français estiment qu’un accès à un point de recharge sur le lieu de travail est très important (source : Institut Kantar).</li>
                <li><strong>Démontrez votre engagement écologique :</strong>  86% des français attendent des entreprises des solutions aux enjeux environnementaux et sociétaux (source : étude Kantar Sustainability Sector Index).</li>
              </ul>
            </div>
            <div class="slider-btn-right">
              <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
            </div>
        </div>
   
        <div class="slider-pagination">
          <ul>
            <li (click)="goToStep(1)">
              <Image type="icon" width="8px" image="ellipsis-grey"></Image>
            </li>
            <li>
              <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
            </li>
          </ul>
        </div>
      </div>
      </div>


    </div>

    
  </div>





  <!-- CUSTOMER REVIEWS -->
  <div id="customersReviews" class="customer-review-container">
    <TitleAtom align="center" color="dark" size="x-large" [mobileSize]="22" weight="semi-bold" text="Nos clients nous recommandent"></TitleAtom>
    <Image *ngIf="currentWidth > 1000" class="customer-reviews-top-left" type="icon" image="customer-reviews-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="customer-reviews-bottom-left" type="icon" image="customer-reviews-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="customer-reviews-bottom-right" type="icon" image="customer-reviews-bottom-right"></Image>
    <Image *ngIf="currentWidth > 1000" class="customer-reviews-top-right" type="icon" image="customer-reviews-top-right"></Image>

    <Image *ngIf="currentWidth < 1000" class="customer-reviews-top-left" type="icon" image="customer-reviews-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="customer-reviews-bottom-left" type="icon" image="customer-reviews-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="customer-reviews-bottom-right" type="icon" image="customer-reviews-bottom-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="customer-reviews-top-right" type="icon" image="customer-reviews-top-right-mobile"></Image>
    <div class="scroll-parent">
      <ul class="scroll-element first">
        <li *ngFor="let review of reviews">
          <CustomerReview initialsColor="blue" [rating]="review.rating" username="{{ review.author_name }}" text="{{ review.text }}"></CustomerReview>
        </li>
        <li *ngFor="let review of reviews">
          <CustomerReview initialsColor="blue" [rating]="review.rating" username="{{ review.author_name }}" text="{{ review.text }}"></CustomerReview>
        </li>
        <li *ngFor="let review of reviews">
          <CustomerReview initialsColor="blue" [rating]="review.rating" username="{{ review.author_name }}" text="{{ review.text }}"></CustomerReview>
        </li>

      </ul>
    </div>
    <GoogleRatingAtom [style]="'dark'"></GoogleRatingAtom>
  </div>


  <!-- OUR ACHIEVEMENTS -->  
  <div id="ourAchievements" class="achievements-container">
    <div class="achievements-header">
      <ParagraphAtom color="orange" align="center" size="small" weight="semi-bold" text="Témoignages d'entreprises"></ParagraphAtom>
      <TitleAtom type="h2" [mobileSize]="22" color="dark" align="center" size="large" weight="semi-bold" text="Nos réalisations"></TitleAtom>
    </div>
    <div class="achievements-content">
      <div class="achievement-item"    (click)="goToLink('/blog/panneaux-solaires-hermitage-moly')"  >
        <div class="achievement-image" >
          <Image type="image" width="100%" image="comsider-panneaux" borderRadius="radius"></Image>

        </div>
        <div class="achievement-details">
          <div class="achievement-label">Réalisation</div>
          <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Installation photovoltaïque pour les locaux Comsider"></TitleAtom>
          <ParagraphAtom color="dark" size="small" weight="regular" text="Installation de 76 kWc pour alimenter les locaux et la flotte automobile de l’entreprise Comsider. "></ParagraphAtom>
        </div>
      </div>
      
      <div class="achievement-item"  (click)="goToLink('/blog/panneaux-solaires-comsider')"  >
        <Image type="image" width="100%" image="entrepot-panneaux" borderRadius="radius"></Image>
        <div class="achievement-details">
          <div class="achievement-label">Réalisation</div>
          <TitleAtom type="h3" color="dark" size="medium" weight="semi-bold" text="Installation photovoltaïque pour l’Hermitage de Molly"></TitleAtom>
          <ParagraphAtom color="dark" size="small" weight="regular" text="Installation de 15 kWc pour alimenter les chambres d’hôtes, les véhicules électrique et la piscine de l’Hermitage de Molly."></ParagraphAtom>
        </div>
      </div>
    </div>
    <div class="achievements-footer">

      <ButtonAtom [style]="'underline'" [routerLink]="'/blog/categorie/realisations/'"   color="dark" text="Découvrir toutes nos réalisations"></ButtonAtom>

 
    </div>
  </div>
  

  <!-- PROJECT INQUIRY -->
  <div id="projectInquiry" class="project-inquiry-container">
    <Image *ngIf="currentWidth > 1000" class="project-inquiry-top-left" type="icon" image="customer-reviews-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="project-inquiry-bottom-left" type="icon" image="customer-reviews-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" class="project-inquiry-bottom-right" type="icon" image="customer-reviews-bottom-right"></Image>
    <Image *ngIf="currentWidth > 1000" class="project-inquiry-top-right" type="icon" image="customer-reviews-top-right"></Image>

    <Image *ngIf="currentWidth < 1000" class="project-inquiry-top-left" type="icon" image="customer-reviews-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="project-inquiry-bottom-left" type="icon" image="customer-reviews-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="project-inquiry-bottom-right" type="icon" image="customer-reviews-bottom-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" class="project-inquiry-top-right" type="icon" image="customer-reviews-top-right-mobile"></Image>
    <div class="project-inquiry-form">
     <div class="project-inquiry-title">
      <TitleAtom type="h2" [mobileSize]="22" color="dark" align="left" size="large" weight="semi-bold" text="Un projet photovoltaïque ? Contactez-nous"></TitleAtom>

     </div>


      <form>
        <ul>
          <li>
            <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre nom*"></ParagraphAtom>
            <InputAtom [style]="'basic'" color="light" name="phoneNumber" [(value)]="formDatas.name"></InputAtom>
          </li>
          <li>
            <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre code postal*"></ParagraphAtom>
            <InputAtom #inputAtom postalCodeAutocomplete [inputElement]="inputAtom.inputRef.nativeElement" placeholder="Saisissez votre adresse" [style]="'basic'" color="light" name="address" [(value)]="this.formDatas.cp" (onSelect)="onPlaceSelected($event)"></InputAtom>
          </li>
          <li>
            <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre téléphone*"></ParagraphAtom>
            <InputAtom type="tel" [maxlength]="10" placeholder="0605040302" icon="+33" [style]="'basic'" color="light" name="phoneNumber" [(value)]="formDatas.phoneNumber"></InputAtom>
          </li>
        </ul>
        <div class="project-inquiry-cta">
          <ButtonAtom [style]="'basic'" color="yellow" text="Demander une étude" ></ButtonAtom>
        </div>
      </form>
    </div>
  </div>
  
  
  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>

  <!-- CONTACT MODAL -->
  <div *ngIf="this.getContactModal === true" class="offer-contact-modal-container">
    <div class="offer-contact-modal">
      <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeContactModal()"></ButtonAtom>
      <TitleAtom color="dark" weight="semi-bold" size="large" text="Choisissez votre mode de contact"></TitleAtom>
      <ul>
        <li>
          <CardInline [style]="'basic'" image="appointment" title="Prendre un rendez-vous téléphonique" btnText="Prendre rendez-vous" btnIcon="calendar" (btnClick)="userGetMeeting()" text="Un appel dure 30 min en moyenne. Vous souhaitez être rappelé à une date/heure précise car vous avez des questions ? Un Conseiller en Energie Solaire vous répond et vous conseille sur votre projet."></CardInline>
        </li>
        <li>
          <CardInline [style]="'basic'" image="callback" title="Être rappelé immédiatement" btnText="Être rappelé" btnIcon="phone" (btnClick)="userGetCall()" text="Vous souhaitez être rappelé immédiatement car vous avez des questions ? Un Conseiller en Energie Solaire vous répond."></CardInline>
        </li>
        <li>
          <CardInline [style]="'basic'" image="phone-contact" title="Appelez-nous !" btnText="04 82 53 72 45" btnIcon="phone" text="Vous êtes disponible immédiatement ? Notre centre d’appel répond à vos premières interrogations et vous oriente vers un Conseiller en Energie Solaire si vous le désirez ensuite."></CardInline>
        </li>
        <li>
          <CardInline [style]="'basic'" image="message" title="Discutons ensemble" btnText="Envoyer un message" btnIcon="message-dark" (btnClick)="userGetMessage()" text="Envoyez-nous votre message. Questions, demande de conseils sur votre projet photovoltaïque... Nous traitons votre demande pour vous conseiller au mieux."></CardInline>
        </li>
      </ul>
    </div>
  </div>

  <!-- MEETING MODAL -->

  <!-- MEETING-STEP-1 -->
  <div *ngIf="this.getMeeting === true" class="call-container">
    <div class="meeting-section">
      <!-- HEADER -->
      <div class="meeting-top">
        <div>
          <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeMeetingModal()"></ButtonAtom>
        </div>
        <TitleAtom color="light" weight="semi-bold" size="large" text="Vous souhaitez prendre un rendez-vous téléphonique"></TitleAtom>
      </div>

      <!-- FORM & CALENDAR -->
      <div class="meeting-bottom">
        <div>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre code postal*"></ParagraphAtom>
          <InputAtom #inputAtom postalCodeAutocomplete [inputElement]="inputAtom.inputRef.nativeElement" placeholder="Saisissez votre adresse" [style]="'basic'" color="{{isPostalCodeNotAvailable() ? 'red' : 'light'}}" name="address" [(value)]="this.formDatas.cp" (onSelect)="onPlaceSelected($event)"></InputAtom>
          <ParagraphAtom *ngIf="isPostalCodeNotAvailable()" color="red" size="small" weight="semi-bold" text="Monabee n’est pas encore présent dans votre ville."></ParagraphAtom>
        </div>
        <div class="calendar-container">
          <dp-day-calendar [(ngModel)]="selectedDate" [theme]="'dp-material'" [config]="datePickerConfig"></dp-day-calendar>
          <div>
            <ParagraphAtom *ngIf="selectedDate === undefined" color="dark" size="intermediate" weight="semi-bold" text="Veuillez sélectionner une date"></ParagraphAtom>
            <ParagraphAtom *ngIf="selectedDate !== undefined" color="dark" size="intermediate" weight="semi-bold" text="{{getFormattedDate(selectedDate)}}"></ParagraphAtom>
            <div *ngIf="selectedDate" class="time-container">
              <ButtonAtom class="time-navigation-btn" [style]="'stroked'" color="light" icon="arrow-top" (click)="showLessTimes()" *ngIf="timesIndex > 0"></ButtonAtom>
              <ul>
                <li *ngFor="let time of getDisplayedTimes()" (click)="onSelectChange(4, time)">
                  <button class="time-selection-btn" [ngClass]="{'selected-time': time === this.formDatas.meetingHour}">{{time}}</button>
                </li>
              </ul>
              <ButtonAtom class="time-navigation-btn" [style]="'stroked'" color="light" icon="arrow-bottom" *ngIf="timesIndex < 3" (click)="showMoreTimes()"></ButtonAtom>
            </div>
          </div>
        </div>
      </div>
      <div class="meeting-btn">
        <ButtonAtom [style]="'basic'" [isDisabled]="isPostalCodeNotAvailable() || !isPostalCodeValid() || this.formDatas.meetingDate === undefined || this.formDatas.meetingHour === ''" color="yellow" text="Envoyer ma demande" (click)="validateMeeting(1)"></ButtonAtom>
      </div>
    </div>
  </div>

  <!--MEETING-STEP-2-->
  <div *ngIf="this.meetingDateIsValidated === true" class="call-container">
    <div class="meeting-form-section">
      <div class="meeting-form-top">
        <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeMeetingModal()"></ButtonAtom>
        <ButtonAtom [style]="'clear'" icon="arrow-left-dark" (click)="userGetMeeting()"></ButtonAtom>
        <TitleAtom color="dark" size="large" weight="semi-bold" text="Vos informations"></TitleAtom>
        <ButtonRadio name="pro-meeting" [style]="'second'" [options]="this.currentWidth > 1024 ? [{value: 'particulier', label: 'Je suis un particulier'}, {value: 'pro', label: 'Je suis un professionnel'}] : [{value: 'particulier', label: 'Particulier'}, {value: 'pro', label: 'Professionnel'}]" (selectedValueChange)="onSelectChange(3, $event)"></ButtonRadio>
        <ul>
          <li>
            <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre nom*"></ParagraphAtom>
            <InputAtom [style]="'basic'" color="light" name="phoneNumber" [(value)]="formDatas.name"></InputAtom>
          </li>
          <li>
            <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre email*"></ParagraphAtom>
            <InputAtom [style]="'basic'" color="light" name="email" type="email" [(value)]="formDatas.email"></InputAtom>
          </li>
          <li *ngIf="this.formDatas.userType2 === 'pro'">
            <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre société*"></ParagraphAtom>
            <InputAtom [style]="'basic'" color="light" name="company" [(value)]="formDatas.company"></InputAtom>
          </li>
          <li>
            <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre téléphone*"></ParagraphAtom>
            <InputAtom type="tel" [maxlength]="10" placeholder="0605040302" icon="+33" [style]="'basic'" color="light" name="phoneNumber" [(value)]="formDatas.phoneNumber"></InputAtom>
          </li>
        </ul>
      </div>
      <div class="meeting-btn">
        <ButtonAtom [style]="'basic'" [isDisabled]="this.formDatas.userType2 === 'pro' ? this.formDatas.name === '' || this.formDatas.email === '' || this.formDatas.phoneNumber.length < 10 || this.formDatas.company === '' : this.formDatas.name === '' || this.formDatas.email === '' || this.formDatas.phoneNumber.length < 10 || this.formDatas.cp === ''" color="yellow" text="Valider" (click)="validateMeeting(3)"></ButtonAtom>
      </div>
    </div>
  </div>

  <!-- MEETING-STEP-3 -->
  <div *ngIf="this.meetingIsValidated === true" class="call-container">
    <div class="call-isValidate">
      <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeMeetingModal()"></ButtonAtom>
      <Image type="icon" image="check-blue" width="56px"></Image>
      <TitleAtom color="dark" size="large" weight="semi-bold" text="Votre prise de rendez-vous a bien été confirmée"></TitleAtom>
      <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Un Conseiller en Energie Solaire vous rappellera"></ParagraphAtom>
      <ParagraphAtom color="dark" size="intermediate" weight="regular" text="le {{getFormattedDate(selectedDate)}} à {{this.formDatas.meetingHour}}"></ParagraphAtom>
      <ButtonAtom [style]="'basic'" [isDisabled]="false" color="yellow" text="Fermer" (click)="closeMeetingModal()"></ButtonAtom>
    </div>
  </div>

  <!-- CALL MODAL -->

  <!-- CALL MODAL-STEP-1 -->
  <div *ngIf="this.getCall === true && this.callIsValidated === false" class="call-container">
    <div class="call-section">
      <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeCallModal()"></ButtonAtom>
      <TitleAtom color="dark" size="large" weight="semi-bold" text="Vous souhaitez être rappelé(e) par un Conseiller"></TitleAtom>
      <div>
        <ParagraphAtom weight="semi-bold" size="intermediate" text="Téléphone*"></ParagraphAtom>
        <InputAtom type="tel" [maxlength]="10" placeholder="0605040302" icon="+33" [style]="'basic'" color="light" name="phoneNumber" [(value)]="formDatas.phoneNumber"></InputAtom>
      </div>
      <ButtonAtom [style]="'basic'" [isDisabled]="this.formDatas.phoneNumber.length < 10" color="yellow" text="Valider" (click)="validateCall()"></ButtonAtom>
    </div>
  </div>

  <!-- CALL MODAL-STEP-2 -->
  <div *ngIf="this.getCall === false && this.callIsValidated === true" class="call-container">
    <div class="call-isValidate">
      <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeCallModal()"></ButtonAtom>
      <Image type="icon" image="check-blue" width="56px"></Image>
      <TitleAtom color="dark" size="large" weight="semi-bold" text="Votre demande a bien été prise en compte"></TitleAtom>
      <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Un Conseiller en Energie Solaire vous rappelle dès que possible."></ParagraphAtom>
      <ButtonAtom [style]="'basic'" [isDisabled]="false" color="yellow" text="Fermer" (click)="closeCallModal()"></ButtonAtom>
    </div>
  </div>

  <!-- SEND MESSAGE MODAL -->

  <!-- SEND-MESSAGE-STEP-1 -->
  <div *ngIf="this.openMessage === true && this.messageIsValidated === false" class="call-container">
    <div class="message-section">
      <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeMessageModal()"></ButtonAtom>
      <TitleAtom color="dark" size="large" weight="semi-bold" text="Discutons ensemble"></TitleAtom>
      <ButtonRadio name="pro-message" [style]="'second'" [options]="this.currentWidth > 1024 ? [{value: 'particulier', label: 'Je suis un particulier'}, {value: 'pro', label: 'Je suis un professionnel'}] : [{value: 'particulier', label: 'Particulier'}, {value: 'pro', label: 'Professionnel'}]" (selectedValueChange)="onSelectChange(5, $event)"></ButtonRadio>
      <ul>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre nom*"></ParagraphAtom>
          <InputAtom [style]="'basic'" color="light" name="name" [(value)]="formDatas.name"></InputAtom>
        </li>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre code postal*"></ParagraphAtom>
          <InputAtom #inputAtom postalCodeAutocomplete [inputElement]="inputAtom.inputRef.nativeElement" placeholder="Saisissez votre adresse" [style]="'basic'" color="light" name="address" [(value)]="this.formDatas.cp" (onSelect)="onPlaceSelected($event)"></InputAtom>
        </li>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre email*"></ParagraphAtom>
          <InputAtom [style]="'basic'" color="light" name="email" type="email" [(value)]="formDatas.email"></InputAtom>
        </li>
        <li *ngIf="this.formDatas.userType3 === 'pro'">
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre société*"></ParagraphAtom>
          <InputAtom [style]="'basic'" color="light" name="company" [(value)]="formDatas.company"></InputAtom>
        </li>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre téléphone*"></ParagraphAtom>
          <InputAtom type="tel" [maxlength]="10" placeholder="0605040302" icon="+33" [style]="'basic'" color="light" name="phoneNumber" [(value)]="formDatas.phoneNumber"></InputAtom>
        </li>
        <li>
          <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre message"></ParagraphAtom>
          <div class="textarea-container">
            <TextareaAtom [(value)]="this.formDatas.message"></TextareaAtom>
          </div>
        </li>
      </ul>
      <div>
        <ButtonAtom [style]="'basic'" [isDisabled]="this.formDatas.userType3 === 'pro' ? this.formDatas.name === '' || this.formDatas.email === '' || this.formDatas.phoneNumber.length < 10 || this.formDatas.cp === '' || this.formDatas.company === '' : this.formDatas.name === '' || this.formDatas.email === '' || this.formDatas.phoneNumber.length < 10 || this.formDatas.cp === ''" color="yellow" text="Envoyer ma demande" (click)="validateMessage()"></ButtonAtom>
      </div>
    </div>
  </div>

  <!-- SEND-MESSAGE-STEP-2 -->
  <div *ngIf="this.openMessage === false && this.messageIsValidated === true" class="call-container">
    <div class="call-isValidate">
      <ButtonAtom [style]="'clear'" icon="close-dark" (click)="closeMessageModal()"></ButtonAtom>
      <Image type="icon" image="check-blue" width="56px"></Image>
      <TitleAtom color="dark" size="large" weight="semi-bold" text="Votre message a bien été envoyé !"></TitleAtom>
      <ParagraphAtom color="dark" size="intermediate" weight="regular" text="Nous vous répondrons dans les plus brefs délais"></ParagraphAtom>
      <ButtonAtom [style]="'basic'" [isDisabled]="false" color="yellow" text="Fermer" (click)="closeMessageModal()"></ButtonAtom>
    </div>
  </div>

</div>
