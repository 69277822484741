<div class="contact-message {{ type }}" *ngIf="isHidden === false">
  <div *ngIf="this.type === 'modal' && isMobile" class="mobile-message-header">
    <ParagraphAtom [mobileSize]="20" color="dark" size="large" weight="medium-weight" text="{{this.isMessageSent === false ? 'Discutons ensemble' : ''}}"></ParagraphAtom>
    <div class="close" (click)="onHideModal()">
      <Image type="icon" image="close-dark" width="14px" ></Image>
    </div>
  </div>
  <div class="form" *ngIf="isMessageSent === false">
    <TitleAtom align="center" *ngIf="!isMobile" color="dark" weight="semi-bold" size="intermediate" text="{{ title }}"></TitleAtom>
    <ButtonRadio *ngIf="!hideRadioBtn" class="contact-message-radio" name="charging-main" selectedOption="{{ userType }}" [style]="'second'" [options]="!isMobile ? [{value: 'particulier', label: 'Je suis un particulier', disabled: disableUserTypeChange}, {value: 'professionnel', label: 'Je suis un professionnel', disabled: disableUserTypeChange}] : [{value: 'particulier', label: 'Particulier', disabled: disableUserTypeChange}, {value: 'professionnel', label: 'Professionnel', disabled: disableUserTypeChange}]" (selectedValueChange)="onSelectChange($event)"></ButtonRadio>

    <div class="close" *ngIf="type === 'modal' && !isMobile" (click)="onHideModal()">
      <Image type="icon" image="close-dark" width="14px" ></Image>
    </div>

    <ul>
      <li>
        <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre nom*"></ParagraphAtom>
        <InputAtom [style]="'basic'" color="light" type="name" name="username" [(value)]="form.name"></InputAtom>
      </li>
      <li>
        <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre code postal*"></ParagraphAtom>
        <InputAtom #inputAtom postalCodeAutocomplete [inputElement]="inputAtom.inputRef.nativeElement" type="number" placeholder="Saisissez votre code postal" (onSelect)="onSelectCpChange($event)" [style]="'basic'" color="{{isPostalCodeNotAvailable() ? 'red' : 'light'}}" name="address" [(value)]="this.form.postal_code"></InputAtom>
        <ParagraphAtom *ngIf="isPostalCodeNotAvailable()" color="red" size="small" weight="medium" text="Monabee n’est pas encore présent dans votre ville."></ParagraphAtom>
      </li>
      <li>
        <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre email*"></ParagraphAtom>
        <InputAtom (input)="onInputChange(1)" [style]="'basic'" color="{{(form.email === '' || checkEmail()) ? 'light' : 'red'}}" name="email" type="email" [(value)]="form.email"></InputAtom>
        <ParagraphAtom *ngIf="!checkEmail() && isEmailInputDirty && form.email !== ''" color="red" size="small" weight="medium" text="Email invalide."></ParagraphAtom>
      </li>
      <li *ngIf="this.userType === 'professionnel'">
        <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre société*"></ParagraphAtom>
        <InputAtom [style]="'basic'" color="light" name="company" [(value)]="form.company"></InputAtom>
      </li>
      <li>
        <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre téléphone*"></ParagraphAtom>
        <InputAtom (input)="onInputChange(2)" type="tel" [maxlength]="10" placeholder="0605040302" icon="+33" [style]="'basic'" color="{{form.phone_number === '' || checkPhoneNumber() ? 'light' : 'red'}}" name="phoneNumber" [(value)]="form.phone_number"></InputAtom>
        <ParagraphAtom *ngIf="!checkPhoneNumber() && isPhoneInputDirty && form.phone_number !== ''" color="red" size="small" weight="medium" text="Numéro de téléphone invalide."></ParagraphAtom>
      </li>
      <li class="form-textarea" *ngIf="cardType === 'message'">
        <ParagraphAtom weight="semi-bold" size="intermediate" text="Votre message"></ParagraphAtom>
        <TextareaAtom [(value)]="this.form.message"></TextareaAtom>
      </li>
    </ul>

    <div class="validate-btn">
      <ButtonAtom [style]="'basic'" color="yellow" [isDisabled]="!this.canSubmit()" text="{{ btnText }}" (click)="onSubmit()"></ButtonAtom>
    </div>

    <div class="opposition-message">
      <ParagraphAtom color="dark" align="left" size="intermediate" weight="regular" text="Vous avez le droit de vous inscrire gratuitement sur la liste d'opposition au démarchage téléphonique."></ParagraphAtom>
    </div>
  </div>

  <div class="is-validated" *ngIf="isMessageSent === true">
    <div *ngIf="type === 'modal' && !isMobile" class="close" (click)="onHideModal()">
      <Image type="icon" image="close-dark" width="14px" ></Image>
    </div>
    <Image type="icon" image="check-blue" width="56px"></Image>
    <TitleAtom [mobileSize]="28" color="dark" align="center" size="large" weight="semi-bold" text="Votre message a bien été envoyé !"></TitleAtom>
    <ParagraphAtom color="dark" align="center" size="intermediate" weight="regular" text="Nous vous répondrons dans les plus brefs délais."></ParagraphAtom>
    <ButtonAtom [style]="'basic'" [isDisabled]="false" color="yellow" text="Fermer" (click)="onHideModal()"></ButtonAtom>
  </div>
</div>
