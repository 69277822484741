import {Component, Inject, NgZone, OnInit, PLATFORM_ID} from '@angular/core';
import {CmsService} from "../../services/cms.service";
import {Meta, Title} from "@angular/platform-browser";
import {CmsFaqType, CmsResponseType} from "../../types/cms.types";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-automation',
  templateUrl: './automation.component.html',
  styleUrls: ['./automation.component.scss']
})
export class AutomationComponent implements OnInit {

  /** The faq from the CMS **/
  public faqs: Array<CmsFaqType> = [];

  /** Show the contact modal **/
  public hideContactModal: boolean = true;


  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    private metaService: Meta,
    private cms: CmsService,
    private ngZone: NgZone,
  ) { }


  /* The width of the user window */
  public currentWidth = 2000;

  ngOnInit(): void {
    // Execute code only on browser
    if (isPlatformBrowser(this.platformId)) {
      this.currentWidth = window.innerWidth;
    }

    // Set title and meta description
    this.titleService.setTitle("Monitoring photovoltaïque, Optimee'z, la  domotique solaire par Monabee");
    this.metaService.updateTag({ name: 'description', content: "Découvrez des équipements connectés pour piloter vos installations solaires, avec Optimee'z, la  domotique solaire par Monabee. Pilotage et monitoring de votre installation photovoltaïque."});

    // Get faq
    this.cms.retrieve("[[at(document.type, \"faq\")][any(document.tags, [\"Domotique\"])]]", "[my.faq.title]", 1, 10).then((content: CmsResponseType) => {
      this.ngZone.run(() => {
        this.faqs = content.results;
      });
    });
  }
}
