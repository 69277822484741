import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from "../../services/api.service";
import { isPlatformBrowser } from "@angular/common";
import { AnalyticsService } from "../../services/analytics.service";
import {StorageService} from "../../services/storage.service";

@Component({
    selector: 'ContactCall',
    templateUrl: './contact-call.component.html',
    styleUrls: ['./contact-call.component.scss']
})
export class ContactCallComponent implements OnInit {

    /** If the modal is hidden */
    @Input() isHidden: boolean = false;
    @Output() isHiddenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** If the form as been validated **/
    public isValidated: boolean = false;

    /** Form */
    public form = {
        phone_number: "",
        campaign: this.storage.getValue('utm_campaign')
    };

    /** The current width of the window user  */
    public currentWidth: number = 2000;

    /** Check if input is typing */
    public isPhoneInputDirty: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private storage: StorageService,
        public analytics: AnalyticsService,
        public router: Router,
        public api: ApiService
    ) { }

    ngOnInit(): void {
        // Execute code only on browser
        if (isPlatformBrowser(this.platformId)) {
            this.currentWidth = window.innerWidth;
        }
    }

    /**
     * Function to close the modal
     */
    public onHideModal(): void {
        this.isHidden = true;
        this.isValidated = false;
        this.isHiddenChange.emit(true);
    }


    /**
     * Function to check if phone number is valid
     */
    public checkPhoneNumber(): boolean {
        return this.form.phone_number.length >= 10;
    }

    /**
     * Function to submit
     */
    public onSubmit(): void {
        this.analytics.trackForm(12737);
        this.api.post(`contact/call`, this.form).subscribe(() => {
            this.isValidated = true;
            this.form.phone_number = "";
        });
    }

    /** Check if input change */
    public onInputChange(number: number): void {
        if (number === 1) {
            this.isPhoneInputDirty = true;
            this.checkPhoneNumber();
        }
    }

}
