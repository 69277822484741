<div class="automation-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>

  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb color="light" [links]="[{title: 'Pilotage des équipements'}]"></Breadcrumb>
    </div>

    <!-- HEROBANNER -->
    <div id="automationHerobanner" class="herobanner-container">
      <div>
        <TitleAtom color="yellow" size="small" weight="semi-bold" text="Domotique solaire"></TitleAtom>
        <TitleAtom type="h1" color="light" size="x-large" weight="semi-bold" text="Faites des économies financières et d'énergie avec un système de monitoring photovoltaïque"></TitleAtom>
        <ParagraphAtom color="light" size="x-large" weight="semi-bold" text="Suivez et ajustez votre production photovoltaïque grâce à nos solutions de domotique solaire"></ParagraphAtom>
        <ParagraphAtom color="light" size="intermediate" weight="regular" text="Le raccordement des panneaux solaires à un <strong>système domotique</strong> optimise l'utilisation de l'énergie solaire. Ce système permet de surveiller en temps réel <strong>votre production photovoltaïque et consommation d'électricité</strong>."></ParagraphAtom>
        <ParagraphAtom color="light" size="intermediate" weight="regular" text="Ainsi, il est possible d'<strong>ajuster votre consommation d’énergie</strong> en activant les appareils électriques durant les heures ensoleillées, optimisant ainsi l'utilisation de l'énergie solaire et réduisant le recours à d'autres sources d'électricité."></ParagraphAtom>
      </div>

      <VideoYoutubeAtom video="https://www.youtube.com/embed/QCweBP2C3es" width="600px" widthMobile="100%" height="335px" heightMobile="192px"></VideoYoutubeAtom>
    </div>
  </div>

  <!-- HOW ITS WORK -->
  <div id="automationWork" class="functioning-container">
    <Image borderRadius="radius" type="image" image="functioning-illustration" width="600px" mobileWidth="100%"></Image>
    <div>
      <TitleAtom [mobileSize]="28" type="h2" color="dark" weight="semi-bold" size="large" text="Quel est le fonctionnement de la domotique solaire chez Monabee ?"></TitleAtom>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Pour optimiser l'autoconsommation photovoltaïque, il est nécessaire <strong>d’utiliser vos équipements électriques pendant les heures ensoleillées</strong>. Pour se faire, les données de votre compteur Linky et du système de pilotage photovoltaïque sont transmises via notre assistant lumineux par une simple connexion wifi."></ParagraphAtom>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Le système surveille en continu la production d'électricité (en kWh) de vos panneaux solaires grâce à un boîtier connecté à votre compteur Linky. En analysant constamment les données, le système comptabilise l'énergie produite et <strong>alloue intelligemment cette énergie aux équipements les plus adaptés</strong> pour maximiser l'efficacité énergétique."></ParagraphAtom>
      <div>
        <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Exemple :"></ParagraphAtom>
        <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Si vous produisez de l'énergie photovoltaïque en surplus, notre système intelligent de pilotage utilisera automatiquement cette électricité pour faire fonctionner votre chauffe-eau. L’objectif, vous l’avez compris, est d’<strong>augmenter son taux d’autoconsommation pour utiliser au mieux son énergie.</strong>"></ParagraphAtom>
      </div>
      <ButtonAtom [style]="'basic'" color="yellow" text="Demander un devis" (click)="hideContactModal = false"></ButtonAtom>
    </div>
  </div>

  <!-- PRODUCTS -->
  <div id="automationProducts" class="products-container">
    <TitleAtom color="light" align="center" type="h2" weight="semi-bold" size="large" text="Monabee a développé une gamme de produits destinés à mesurer, optimiser et piloter l’autoconsommation photovoltaïque"></TitleAtom>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-top-left" image="apply-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-top-right" image="apply-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-bottom-left" image="apply-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-bottom-right" image="apply-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-top-left" image="apply-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-top-right" image="apply-top-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-bottom-left" image="apply-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-bottom-right" image="apply-bottom-right-mobile"></Image>
    <ul>
      <li>
        <Image borderRadius="radius" type="image" width="576px" imageCloudinary="domotique/image_2_lecteur_gt78sb.jpg"></Image>
        <TitleAtom [mobileSize]="24" type="h3" color="light" weight="semi-bold" size="intermediate" text="Le lecteur d’énergie"></TitleAtom>
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="Le lecteur d’énergie est auto-alimenté par le compteur Linky. Une connexion sans fil et sans piles qui garantit une installation rapide et une connexion permanente. La téléinformation client (TIC) permet de relever des données précises (plages tarifaires, pics de consommations…) et identiques à votre facture. Des informations fiables, pour des conseils personnalisés !"></ParagraphAtom>
      </li>
      <li>
        <Image borderRadius="radius" type="image" width="576px" imageCloudinary="domotique/image_3_module_wtlenh.jpg"></Image>
        <TitleAtom [mobileSize]="24" type="h3" color="light" weight="semi-bold" size="intermediate" text="Le module de gestion"></TitleAtom>
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="Le module de gestion s’intègre à votre tableau électrique. Il permet de mesurer votre consommation d’électricité et votre production solaire puis de piloter ses équipements énergivores"></ParagraphAtom>
      </li>
      <li>
        <Image borderRadius="radius" type="image" width="576px" imageCloudinary="domotique/image_4_assistant_lumineux_d8g56i.jpg"></Image>
        <TitleAtom [mobileSize]="24" type="h3" color="light" weight="semi-bold" size="intermediate" text="L’assistant lumineux"></TitleAtom>
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="L’assistant lumineux permet de visualiser en temps réel grâce à un système de couleurs, l’état de production de votre installation. Il est branché dans votre habitat, communique avec tous les autres équipements de monitoring de Monabee : ERL, module de gestion, Borne de recharge, et fonctionne grâce à votre wifi"></ParagraphAtom>
      </li>
      <li>
        <Image borderRadius="radius" type="image" width="576px" imageCloudinary="domotique/image_5_appli_fsy6mw.jpg"></Image>
        <TitleAtom [mobileSize]="24" type="h3" color="light" weight="semi-bold" size="intermediate" text="L’application Optimee'z"></TitleAtom>
        <ParagraphAtom color="light" weight="regular" size="intermediate" text="L’application Optimee'z regroupe l’ensemble des informations de production et de consommation de votre installation photovoltaïque. Elle vous permet d’avoir une vue d’ensemble et de déclencher ou non certains appareils."></ParagraphAtom>
      </li>
    </ul>
  </div>

  <!-- CONTROL -->
  <div class="control-container">
    <div id="automationControl">
      <TitleAtom [mobileSize]="28" type="h2" color="dark" weight="semi-bold" size="large" text="Pilotez votre installation photovoltaïque grâce à la domotique solaire"></TitleAtom>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Cette solution intelligente vous permet de comprendre et d’optimiser la consommation de votre électricité. Nous collectons les informations de votre installation photovoltaïque, que vous pouvez retrouver dans l’application Optimee'z (application réservée à nos clients)."></ParagraphAtom>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" text="L'application Optimee'z (développée par Monabee pour nos clients) vous indique en instantané :"></ParagraphAtom>
      <ul>
        <li>
          <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Votre production en kW"></ParagraphAtom>
        </li>
        <li>
          <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Votre production disponible en kW pour alimenter vos équipements"></ParagraphAtom>
        </li>
        <li>
          <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Vos achats sur le réseau en kW"></ParagraphAtom>
        </li>
        <li>
          <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="Votre pourcentage d'autoconsommation"></ParagraphAtom>
        </li>
      </ul>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" text="Toutes ces informations peuvent être suivies en temps réel, mais aussi quotidiennement, mensuellement ou annuellement. De plus, nous vous indiquons votre consommation d’énergie comparée à une période précédente. Cet indicateur vous permet de savoir si vous avez mieux autoconsommé votre énergie solaire que le mois dernier par exemple. "></ParagraphAtom>
      <ButtonAtom [style]="'basic'" color="yellow" text="Demander un devis" (click)="hideContactModal = false"></ButtonAtom>
    </div>
    <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" imageCloudinary="domotique/Domotique_pilotage-installation_hg3v50.jpg"></Image>
  </div>


  <!-- FAQ -->
  <div id="automationFaq" class="faq-container">
    <TitleAtom type="h2" size="x-large" weight="semi-bold" color="dark" text="Vos questions sur la domotique solaire"></TitleAtom>
    <ul>
      <li *ngFor="let faq of faqs">
        <Faq title="{{ faq.data.title }}" [text]="faq.data.body"></Faq>
      </li>
    </ul>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>

<ContactMessage title="Demander un devis gratuitement" btnText="Demander un devis gratuitement" userType="particulier" type="modal" [(isHidden)]="hideContactModal"></ContactMessage>
