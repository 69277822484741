import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { Router, Routes, RouterModule, ExtraOptions } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';

import { GoogleMapsModule } from '@angular/google-maps';
import { MapsAutocompleteDirective } from './services/maps-autocomplete.directive';
import { PostalCodeAutocompleteDirective } from './services/postalCode.directive';
import { DpDatePickerModule } from 'ng2-date-picker';
import { AppComponent } from './app.component';
import { ParagraphComponent } from './atom/paragraph/paragraph.component';
import { ButtonComponent } from './atom/button/button.component';
import { AnnouncementComponent } from './molecule/announcement/announcement.component';
import { TitleComponent } from './atom/title/title.component';
import { InputComponent } from './atom/input/input.component';
import { CmsHtmlRendererComponent } from './atom/cms-html-renderer/cms-html-renderer.component';
import { SearchSimulationComponent } from './molecule/search-simulation/search-simulation.component';
import { KeyPointComponent } from './molecule/key-point/key-point.component';
import { ImageComponent } from './atom/image/image.component';
import { CardCustomerReviewComponent } from './molecule/card-customer-review/card-customer-review.component';
import { NavbarComponent } from './molecule/navbar/navbar.component';
import { ListLinkComponent } from './molecule/list-link/list-link.component';
import { VideoComponent } from './atom/video/video.component';
import { VideoYoutubeComponent } from './atom/video-youtube/video-youtube.component';
import { CardContentComponent } from './molecule/card-content/card-content.component';
import { SimulatorComponent } from './pages/simulator/simulator.component';
import { SelectComponent } from './atom/select/select.component';
import { CheckboxComponent } from './atom/checkbox/checkbox.component';
import { ButtonRadioComponent } from './atom/button-radio/button-radio.component';
import { ToggleComponent } from './atom/toggle/toggle.component';
import { FaqComponent } from './molecule/faq/faq.component';
import { CardArticleComponent } from './molecule/card-article/card-article.component';
import { CardMeetingComponent } from './molecule/card-meeting/card-meeting.component';
import { FooterComponent } from './molecule/footer/footer.component';
import { BreadcrumbComponent } from './molecule/breadcrumb/breadcrumb.component';
import { CardInlineComponent } from './molecule/card-inline/card-inline.component';
import { CardInlineListComponent } from './molecule/card-inline-list/card-inline-list.component';
import { TextareaComponent } from './atom/textarea/textarea.component';
import { CardHiringComponent } from './molecule/card-hiring/card-hiring.component';
import { ContactMessageComponent } from './molecule/contact-message/contact-message.component';
import { ContactCallComponent } from './molecule/contact-call/contact-call.component';
import { ContactMeetingComponent } from './molecule/contact-meeting/contact-meeting.component';
import { SliderComponent } from './molecule/slider/slider.component';
import { GoogleRatingComponent } from './atom/google-rating/google-rating.component';
import { NotificationComponent } from "./molecule/notification/notification.component";


import { HomeComponent } from './pages/home/home.component';
import { FaqPageComponent } from "./pages/faq/faq.component";
import { OfferProduceComponent } from './pages/offer-produce/offer-produce.component';
import { OfferChargingComponent } from './pages/offer-charging/offer-charging.component';
import { OfferProComponent } from './pages/offer-pro/offer-pro.component';
import { BlogListPageComponent } from "./pages/blog-list/blog-list.component";
import { BlogCategoryPageComponent } from "./pages/blog-category/blog-category.component";
import { BlogDetailPageComponent } from "./pages/blog-detail/blog-detail.component";
import { ContentCmsPageComponent } from "./pages/content-cms/content-cms.component";
import { ContentCms2PageComponent } from "./pages/content-cms-2/content-cms-2.component";
import { TestimonialsPageComponent } from "./pages/testimonials/testimonials.component";
import { TestimonialsDetailPageComponent } from "./pages/testimonials-detail/testimonials-detail.component";
import { HrListComponent } from './pages/hr-list/hr-list.component';
import { AutomationComponent } from './pages/automation/automation.component';
import { HrDetailComponent } from './pages/hr-detail/hr-detail.component';
import { GuideListComponent } from './pages/guide-list/guide-list.component';
import { GuideDetailPageComponent } from './pages/guide-detail/guide-detail.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ApplyComponent } from './pages/apply/apply.component';
import { CommitmentsComponent } from './pages/commitments/commitments.component';
import { HistoryComponent } from './pages/history/history.component';

import 'dayjs/locale/fr';
import localeFr from '@angular/common/locales/fr';
import * as dayjs from 'dayjs';
import * as Sentry from "@sentry/angular-ivy";
import { SafePipe } from "./pipes/safe.pipes";
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { PressListComponent } from './pages/press-list/press-list.component';
import { ErrorComponent } from './pages/error/error.component';
import { AuthorComponent } from './pages/author/author.component';
import { CardAuthorComponent } from './atom/card-author/card-author.component';
import {FormArticleComponent} from "./molecule/form-article/form-article.component";
import {TemplateComponent} from "./pages/template/template.component";

export function playerFactory() {
    return player;
}

registerLocaleData(localeFr, 'fr');
dayjs.locale('fr');

export class CustomeUrlSerializer implements UrlSerializer {
    parse(url: any): UrlTree {
        let dus = new DefaultUrlSerializer();
        return dus.parse(url);
    }

    serialize(tree: UrlTree): any {
        let dus = new DefaultUrlSerializer(), path = dus.serialize(tree);
        let pathArr = path.split('?');
        // if (pathArr[0] === '/') return '/';
        if (pathArr[0] !== '/') pathArr[0] += "/";;

        // pathArr[0] += "/";
        let joinSeparator = pathArr.length > 1 ? "?" : "";
        path = pathArr.join(joinSeparator);
        return path;
    }
}

const appRouter: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'simulateur-panneau-solaire', component: SimulatorComponent },
    { path: 'faq', component: FaqPageComponent },
    { path: 'blog', component: BlogListPageComponent },
    { path: 'blog/categorie/:category', component: BlogCategoryPageComponent },
    { path: 'blog/:article', component: BlogDetailPageComponent },
    { path: 'guides', component: GuideListComponent },
    { path: 'guides/:guide', component: GuideDetailPageComponent },
    { path: 'conseils/:page', component: ContentCmsPageComponent },
    { path: 'offre/:template_page_1', component: TemplateComponent },
    { path: 'evenement/:template_page_1', component: TemplateComponent },
    { path: 'temoignage-client', component: TestimonialsPageComponent },
    { path: 'temoignage-client/:article', component: TestimonialsDetailPageComponent },
    { path: 'presse', component: PressListComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'recrutement', component: HrListComponent },
    { path: 'recrutement/:job', component: HrDetailComponent },
    { path: 'recrutement/offres/postuler', component: ApplyComponent },
    { path: 'installer-panneaux-solaires', component: OfferProduceComponent },
    { path: 'installer-borne-de-recharge-solaire', component: OfferChargingComponent },
    { path: 'installer-panneaux-solaires-professionnels', component: OfferProComponent },
    { path: 'entreprise', component: CommitmentsComponent },
    { path: 'histoire', component: HistoryComponent },
    { path: 'domotique-solaire-monitoring-photovoltaique', component: AutomationComponent },
    { path: 'mentions-legales', component: ContentCms2PageComponent },
    { path: 'donnees-personnelles-politique-de-confidentialite', component: ContentCms2PageComponent },
    { path: 'auteurs', component: AuthorComponent},
    { path: '**', pathMatch: 'full', component: ErrorComponent },

];

const routerOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking',

    errorHandler: (e) => {
        console.error(e);
        throw e;
    }
};

@NgModule({
    declarations: [
        AppComponent,
        FaqPageComponent,
        BlogListPageComponent,
        BlogCategoryPageComponent,
        BlogDetailPageComponent,
        SimulatorComponent,
        ContentCmsPageComponent,
        TemplateComponent,
        ContentCms2PageComponent,
        TestimonialsPageComponent,
        TestimonialsDetailPageComponent,
        OfferProduceComponent,
        OfferChargingComponent,
        OfferProComponent,
        ApplyComponent,
        AutomationComponent,
        HrListComponent,
        HrDetailComponent,
        GuideListComponent,
        GuideDetailPageComponent,
        ContactMessageComponent,
        ContactCallComponent,
        ContactMeetingComponent,
        SliderComponent,
        GoogleRatingComponent,
        NotificationComponent,
        FormArticleComponent,

        ParagraphComponent,
        ButtonComponent,
        AnnouncementComponent,
        HomeComponent,
        TitleComponent,
        InputComponent,
        SearchSimulationComponent,
        CheckboxComponent,
        KeyPointComponent,
        ImageComponent,
        BreadcrumbComponent,
        CmsHtmlRendererComponent,
        VideoComponent,
        VideoYoutubeComponent,
        CardContentComponent,
        NavbarComponent,
        ListLinkComponent,
        CardCustomerReviewComponent,
        CardArticleComponent,
        CardMeetingComponent,
        FaqComponent,
        FooterComponent,
        ContactComponent,
        CardInlineComponent,
        CardInlineListComponent,
        TextareaComponent,
        ButtonRadioComponent,
        SelectComponent,
        MapsAutocompleteDirective,
        PostalCodeAutocompleteDirective,
        ToggleComponent,
        CardHiringComponent,
        ApplyComponent,
        CommitmentsComponent,
        HistoryComponent,

        SafePipe,
        PressListComponent,
        ErrorComponent,
        AuthorComponent,
        CardAuthorComponent,

    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(appRouter, routerOptions),
        FormsModule,
        HttpClientModule,
        GoogleMapsModule,
        DpDatePickerModule,
        LottieModule.forRoot({ player: playerFactory })
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'fr' },
        { provide: UrlSerializer, useClass: CustomeUrlSerializer },
        // {
        //     provide: ErrorHandler,
        //     useValue: Sentry.createErrorHandler({
        //         showDialog: false,
        //     }),
        // },
        // {
        //     provide: Sentry.TraceService,
        //     deps: [Router],
        // },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            // deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})

export class AppModule {}
